import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer'; 

function RefundPolicy() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

    // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount

  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}
         <div className="refund-policy">
      <h1>Refund Policy for AmaniTouch</h1>
      <p>
        At AmaniTouch, we strive to provide exceptional service to both our clients and therapists. We understand that sometimes situations arise that may require a refund. This policy outlines our guidelines regarding refunds for appointments made through our platform.
      </p>

      <section className="refund-section">
        <h2>Refund Eligibility</h2>
        <p>
          Clients may request a refund under the following circumstances:
        </p>
        <ul>
          <li>Appointment cancellation made at least 24 hours in advance.</li>
          <li>Unresolved issues with service quality or therapist conduct during the session.</li>
          <li>Double booking or scheduling errors that affect the appointment.</li>
        </ul>
      </section>

      <section className="refund-section">
        <h2>Requesting a Refund</h2>
        <p>
          To request a refund, clients must:
        </p>
        <ul>
          <li>Contact our customer support team via email at <a href="mailto:support@amanitouch.com">support@amanitouch.com</a> or through the in-app messaging system.</li>
          <li>Provide relevant details, including the appointment date, therapist name, and reason for the refund request.</li>
          <li>Submit the refund request within 7 days of the scheduled appointment.</li>
        </ul>
      </section>

      <section className="refund-section">
        <h2>Processing Refunds</h2>
        <p>
          Refunds will be processed within 7-10 business days after the refund request has been approved. The funds will be returned to the original payment method used at the time of booking.
        </p>
      </section>

      <section className="refund-section">
        <h2>Non-Refundable Services</h2>
        <p>
          Certain services may be non-refundable, including but not limited to:
        </p>
        <ul>
          <li>Packages or subscriptions that have already been partially used.</li>
          <li>Appointment cancellations made less than 24 hours in advance.</li>
          <li>Clients who have violated our community guidelines or terms of service.</li>
        </ul>
      </section>

      <section className="refund-section">
        <h2>Changes to the Refund Policy</h2>
        <p>
          AmaniTouch reserves the right to modify this refund policy at any time. Any changes will be posted on this page, and clients are encouraged to review the policy periodically.
        </p>
      </section>

      <section className="refund-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions regarding this refund policy or need assistance with your refund request, please contact our support team at <a href="mailto:info@amanitouch.app">info@amanitouch.app</a>.
        </p>
      </section>
    </div>

    <Footer />

    </div>
  )
}

export default RefundPolicy