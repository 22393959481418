import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';

function PrivacyPolicy() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

    // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount
  

  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}

        <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      
      <section className="introduction">
        <h2>1. Introduction</h2>
        <p>
          At AmaniTouch, we value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, share, and protect your information when you use our platform and services. By using AmaniTouch, you agree to the terms of this Privacy Policy.
        </p>
      </section>

      <section className="data-we-collect">
        <h2>2. Information We Collect</h2>
        <p>
          We collect the following types of information when you use our platform:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> Your name, email address, phone number, and payment information when you sign up or make a transaction.</li>
          <li><strong>Profile Information:</strong> Details such as your bio, location, and services offered for therapists.</li>
          <li><strong>Usage Data:</strong> Information about how you use our platform, such as your booking history, interactions with other users, and search queries.</li>
          <li><strong>Device Information:</strong> Details about the device you use to access AmaniTouch, including IP address, browser type, and operating system.</li>
          <li><strong>Location Data:</strong> Geolocation data to show services and therapists near you, if you allow us to access this information.</li>
        </ul>
      </section>

      <section className="how-we-use-data">
        <h2>3. How We Use Your Information</h2>
        <p>
          We use your data to:
        </p>
        <ul>
          <li>Provide and personalize our services to you.</li>
          <li>Facilitate communication between clients and therapists.</li>
          <li>Process transactions and manage payments.</li>
          <li>Improve our platform by analyzing usage data.</li>
          <li>Send important notifications, such as booking confirmations and updates.</li>
          <li>Promote safety and security on our platform by detecting and preventing fraud or other harmful activities.</li>
        </ul>
      </section>

      <section className="sharing-data">
        <h2>4. How We Share Your Information</h2>
        <p>
          We may share your personal data in the following circumstances:
        </p>
        <ul>
          <li><strong>With Other Users:</strong> Information such as your profile, services offered, and contact details are shared with clients or therapists when you interact with them.</li>
          <li><strong>With Service Providers:</strong> We may share your data with third-party service providers who help us run our platform, such as payment processors or customer support.</li>
          <li><strong>For Legal Reasons:</strong> If required by law, or to protect the rights and safety of AmaniTouch, we may share your information with law enforcement or other third parties.</li>
          <li><strong>With Your Consent:</strong> We may share your data for other purposes if you provide explicit consent.</li>
        </ul>
      </section>

      <section className="cookies-tracking">
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          AmaniTouch uses cookies and similar tracking technologies to enhance your experience, analyze usage, and improve our platform. You can choose to disable cookies in your browser, but doing so may affect the functionality of the platform.
        </p>
      </section>

      <section className="data-security">
        <h2>6. Data Security</h2>
        <p>
          We take reasonable steps to protect your data from unauthorized access, loss, or misuse. However, no system is completely secure, and we cannot guarantee the absolute security of your data.
        </p>
      </section>

      <section className="data-retention">
        <h2>7. Data Retention</h2>
        <p>
          We retain your personal data for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. When your data is no longer needed, we will delete or anonymize it.
        </p>
      </section>

      <section className="your-rights">
        <h2>8. Your Rights</h2>
        <p>
          You have the following rights regarding your personal data:
        </p>
        <ul>
          <li><strong>Access and Correction:</strong> You can access and update your personal information in your account settings.</li>
          <li><strong>Deletion:</strong> You can request that we delete your personal data, subject to legal and contractual obligations.</li>
          <li><strong>Opt-Out:</strong> You can opt out of receiving marketing communications by following the unsubscribe instructions in our emails.</li>
          <li><strong>Data Portability:</strong> You can request a copy of your data in a machine-readable format.</li>
        </ul>
      </section>

      <section className="third-party-links">
        <h2>9. Third-Party Links</h2>
        <p>
          Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. We encourage you to review their privacy policies before providing any personal information.
        </p>
      </section>

      <section className="policy-updates">
        <h2>10. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised. Your continued use of AmaniTouch after the changes take effect constitutes your acceptance of the updated policy.
        </p>
      </section>
    </div>



        <Footer />
    </div>
  )
}

export default PrivacyPolicy