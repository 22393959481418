import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';

function HelpCentre() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

    // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount
  
    
  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}

        <div className='aboutTopBar'>
        <div className='funnyOpacityCover'></div>
        <div className='aboutTopBarCont'>
          <h1 className="pageTitle">Help Centre</h1>
          <p>Your Trusted Platform for Professional Massage Therapy</p>
        </div>
      </div>

        <div>
        <div className="help-centre">

      <section className="getting-started">
        <h2>1. Getting Started</h2>
        <h4>How do I sign up?</h4>
        <p>
          To sign up as a client or therapist, simply click the "Sign Up" button on the homepage and fill in your details. You can sign up using your email or social media accounts.
        </p>

        <h4>How do I book a massage?</h4>
        <p>
          Once you’ve signed up, browse the available therapists by location, services, or reviews. Select a therapist, choose your desired service, and confirm the date and time for your session.
        </p>

        <h4>How do I become a therapist on AmaniTouch?</h4>
        <p>
          To become a massage therapist, go to the <strong>Sign Up as Therapist</strong> page, create an account, and provide details about your qualifications, services, pricing, and availability.
        </p>
      </section>

      <section className="account-management">
        <h2>2. Account Management</h2>

        <h4>How do I update my profile information?</h4>
        <p>
          You can update your personal or professional information by going to the <strong>Account Settings</strong> page. Here, you can change your profile picture, bio, contact information, and services.
        </p>

        <h4>How do I change my password?</h4>
        <p>
          Navigate to the <strong>Account Settings</strong>, select <strong>Security</strong>, and follow the instructions to update your password.
        </p>

        <h4>How can I upgrade my account?</h4>
        <p>
          You can upgrade your account to access premium features by visiting the <strong>Upgrade Plan</strong> section in your account settings. This allows you to unlock additional services and benefits.
        </p>
      </section>

      <section className="booking-payments">
        <h2>3. Booking and Payments</h2>

        <h4>How do I make a payment?</h4>
        <p>
          After selecting your desired service and therapist, you can securely make payments through our platform using various payment methods, including credit/debit cards and mobile payments.
        </p>

        <h4>Can I cancel or reschedule a booking?</h4>
        <p>
          Yes, you can cancel or reschedule a booking. Visit your <strong>Bookings</strong> page, choose the appointment, and select <strong>Cancel</strong> or <strong>Reschedule</strong>. Note that cancellation policies may apply based on the therapist's terms.
        </p>

        <h4>What is your refund policy?</h4>
        <p>
          If you are unsatisfied with your session or need a refund for a canceled booking, please refer to our <strong>Refund Policy</strong> page. Refund requests are processed based on the therapist's cancellation terms.
        </p>
      </section>

      <section className="therapist-support">
        <h2>4. Therapist Support</h2>

        <h4>How do I set up my availability?</h4>
        <p>
          As a therapist, you can manage your availability by selecting the days and times you're open for appointments through your <strong>Schedule</strong> in the <strong>Therapist Dashboard</strong>.
        </p>

        <h4>How do I manage my bookings?</h4>
        <p>
          All your upcoming and past bookings are listed in the <strong>Bookings</strong> section. From here, you can view client details, accept or decline bookings, and manage your schedule.
        </p>

        <h4>How can I increase my visibility to clients?</h4>
        <p>
          Make sure your profile is complete with high-quality images, detailed service descriptions, and competitive pricing. You can also respond to reviews and offer special promotions to attract new clients.
        </p>
      </section>

      <section className="safety-trust">
        <h2>5. Safety and Trust</h2>

        <h4>Are all therapists verified?</h4>
        <p>
          Yes, all therapists on AmaniTouch are verified for their qualifications and background before they can offer services on our platform. We prioritize your safety and ensure that each therapist meets our standards.
        </p>

        <h4>How are payments secured?</h4>
        <p>
          All payments made through AmaniTouch are processed via secure, encrypted payment gateways to protect your financial information. We do not store any sensitive payment data.
        </p>

        <h4>How do I report a problem or file a complaint?</h4>
        <p>
          If you encounter any issues with a therapist or the service you received, you can report it through the <strong>Help</strong> section in your account. We take complaints seriously and will work to resolve them promptly.
        </p>
      </section>

      <section className="technical-issues">
        <h2>6. Technical Issues</h2>

        <h4>Why can’t I log into my account?</h4>
        <p>
          If you're having trouble logging in, double-check your credentials or reset your password via the <strong>Forgot Password</strong> link. If the issue persists, contact our support team.
        </p>

        <h4>What should I do if the app is not working?</h4>
        <p>
          Ensure that you have the latest version of the app installed. If you’re still facing issues, try restarting your device or clearing your browser’s cache. Contact support if the problem continues.
        </p>
      </section>

      <section className="contact-us">
        <h2>7. Contact Us</h2>

        <p>
          Still need help? Reach out to us, and we'll assist you as quickly as possible.
        </p>

        <ul>
          <li>Phone Support: +44 71234567</li>
          <li>Email: support@amanitouch.app</li>
          <li>Live Chat: Available 24/7 via the chat icon on the website</li>
          <li>Find us on: <a href="#">Facebook</a>, <a href="#">Twitter</a>, <a href="#">Instagram</a></li>
        </ul>
      </section>
    </div>
        </div>


        <Footer />
    </div>
  )
}

export default HelpCentre