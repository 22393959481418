import React, { useState } from "react";

function TestComponents() {
    
    const [longUrl, setLongUrl] = useState("");
    const [shortUrl, setShortUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError("");
      setShortUrl("");
  
      try {
        const response = await fetch(
          `https://api.shrtco.de/v2/shorten?url=${longUrl}`
        );
        const data = await response.json();
  
        if (data.ok) {
          setShortUrl(data.result.full_short_link);
        } else {
          setError("Failed to shorten the URL. Please try again.");
        }
      } catch (err) {
        setError("An error occurred. Please check the URL and try again.");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div style={{ padding: "2rem", textAlign: "center" }}>
        <h1>URL Shortener</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="url"
            placeholder="Enter URL here..."
            value={longUrl}
            onChange={(e) => setLongUrl(e.target.value)}
            required
            style={{ padding: "0.5rem", width: "80%" }}
          />
          <button
            type="submit"
            style={{
              padding: "0.5rem 1rem",
              marginLeft: "0.5rem",
              backgroundColor: "blue",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            Shorten
          </button>
        </form>
  
        {loading && <p>Shortening...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {shortUrl && (
          <p>
            Shortened URL:{" "}
            <a href={shortUrl} target="_blank" rel="noopener noreferrer">
              {shortUrl}
            </a>
          </p>
        )}
      </div>
    );
};

export default TestComponents