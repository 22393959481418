import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';

function Faqs() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else { 
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

    // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount 

  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}

        <div className="faq">
      <h1>Frequently Asked Questions (FAQ)</h1>

      <section className="faq-section">
        <h2>General Questions</h2>

        <div className="faq-item">
          <h3>What is AmaniTouch?</h3>
          <p>
            AmaniTouch is a platform that connects professional massage therapists with clients looking for personalized, high-quality massage services. Whether you’re in need of relaxation, pain relief, or specialized therapy, you can find certified therapists near you.
          </p>
        </div>

        <div className="faq-item">
          <h3>How does AmaniTouch work?</h3>
          <p>
            For clients, simply sign up, search for therapists near you, and book your preferred service. For therapists, create a profile, list your services, and start receiving bookings from clients around you. AmaniTouch provides an easy and secure way to manage appointments and payments.
          </p>
        </div>
      </section>

      <section className="faq-section">
        <h2>For Clients</h2>

        <div className="faq-item">
          <h3>How do I book a massage session?</h3>
          <p>
            To book a session, log in to your AmaniTouch account, browse the list of available therapists in your area, select the one that fits your needs, choose a service, and confirm the booking. You will receive a confirmation email with the appointment details.
          </p>
        </div>

        <div className="faq-item">
          <h3>Can I cancel or reschedule my booking?</h3>
          <p>
            Yes, you can cancel or reschedule your booking before the session begins. Simply go to your bookings in your account and select the cancellation or rescheduling option. Please note that cancellation policies may vary depending on the therapist.
          </p>
        </div>

        <div className="faq-item">
          <h3>How do I pay for my session?</h3>
          <p>
            Payments are made directly through the AmaniTouch platform. You can pay using your preferred payment method at the time of booking, ensuring a secure and seamless transaction.
          </p>
        </div>
      </section>

      <section className="faq-section">
        <h2>For Therapists</h2>

        <div className="faq-item">
          <h3>How do I join as a therapist?</h3>
          <p>
            To join AmaniTouch as a therapist, click on the "Sign Up as a Therapist" button and complete the registration process. You’ll be required to provide your personal details, qualifications, and services offered, along with pricing information.
          </p>
        </div>

        <div className="faq-item">
          <h3>How do I manage bookings?</h3>
          <p>
            Once your profile is live, you can manage bookings through your dashboard. You will be notified of new bookings and have the option to confirm, reschedule, or decline them. You can also view your booking history and keep track of client feedback.
          </p>
        </div>

        <div className="faq-item">
          <h3>How do I get paid?</h3>
          <p>
            Payments for services are processed through the platform, and earnings will be deposited directly into your bank account or preferred payment method. You can view and manage your earnings in the "Payments" section of your dashboard.
          </p>
        </div>
      </section>

      <section className="faq-section">
        <h2>Account and Subscription</h2>

        <div className="faq-item">
          <h3>Is AmaniTouch free to use?</h3>
          <p>
            AmaniTouch offers both free and paid subscription plans. The free version provides access to basic features, while the premium plan offers additional tools and features to enhance your experience as a therapist or client.
          </p>
        </div>

        <div className="faq-item">
          <h3>How do I upgrade to the premium plan?</h3>
          <p>
            To upgrade to the premium plan, log in to your account, go to the "Upgrade" section, and choose the subscription that fits your needs. Follow the instructions to complete the payment and unlock premium features.
          </p>
        </div>

        <div className="faq-item">
          <h3>How do I delete my account?</h3>
          <p>
            If you wish to delete your AmaniTouch account, go to your account settings and click on the "Delete Account" option. Please note that this action is irreversible, and all your data will be permanently removed.
          </p>
        </div>
      </section>

      <section className="faq-section">
        <h2>Support and Assistance</h2>

        <div className="faq-item">
          <h3>How do I contact customer support?</h3>
          <p>
            If you need assistance or have any questions, you can contact our customer support team by sending an email to support@amanitouch.com or by filling out the contact form in the "Help Center" section of our website.
          </p>
        </div>

        <div className="faq-item">
          <h3>What should I do if I encounter a problem with the platform?</h3>
          <p>
            If you experience any issues with the AmaniTouch platform, please report the problem by visiting the "Help Center" or contacting our support team. We will investigate and resolve the issue as quickly as possible.
          </p>
        </div>
      </section>
    </div>



        <Footer />
    </div>
  )
}

export default Faqs