import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import dashImage from '../Images/dashone.svg'

function Earnings() {
  const auth = getAuth();
  const db = getFirestore();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [earnings, setEarnings] = useState([]); // To store combined earnings data
  const [userID, setUserID] = useState(null); // To store the logged-in user's ID
  const [totalAmount, setTotalAmount] = useState(0); // To store the total amount

  // Handle user authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserID(user.uid); // Store the logged-in user's ID
      } else {
        setIsLoggedIn(false);
        setUserID(null); // Clear the user ID if not logged in
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Fetch earnings data from two collections
  useEffect(() => {
    const fetchEarnings = async () => {
      if (!userID) return; // Wait until the user is logged in

      try {
        // Fetch from "videoCallPayments" collection
        const videoCallQuery = query(
          collection(db, "videoCallPayments"),
          where("payeeID", "==", userID)
        );

        const videoCallSnapshot = await getDocs(videoCallQuery);
        const videoCallData = videoCallSnapshot.docs.map(doc => ({
          id: doc.id,
          date: doc.data().timestamp.toDate().toLocaleDateString(),
          source: "Video Call",
          transRef: doc.data().theRoomID || "N/A",
          amount: parseFloat(doc.data().totalPayment || "0"), // Ensure amount is a number
        }));

        // Fetch from "consultationPayments" collection
        const newCollectionQuery = query(
          collection(db, "phoneViews"),
          where("userId", "==", userID)
        );

        const newCollectionSnapshot = await getDocs(newCollectionQuery);
        const newCollectionData = newCollectionSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            date: data.timestamp.toDate().toLocaleDateString(),
            source: "Phone Number View",
            transRef: data.transRef || "N/A", // Ensure transRef is mapped
            amount: parseFloat(data.totalPayment || "0"), // Ensure amount is a number
          };
        });

        // Merge the data from both collections
        const allEarnings = [...videoCallData, ...newCollectionData];
        setEarnings(allEarnings);

        // Calculate the total amount
        const total = allEarnings.reduce((sum, earning) => sum + earning.amount, 0);
        setTotalAmount(total);
      } catch (error) {
        console.error("Error fetching earnings:", error);
      }
    };

    fetchEarnings();
  }, [db, userID]);

  // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isLoggedIn ? <NavBarAccount /> : <NavBar />}
      
      <div className='myEarnings'>
        <div className='myEarningsTop'>
          <div className='earningsLeft'>
            <img src={dashImage} alt="" />
            <div className='earningsLeftDiv'>
              <div>
                <h5>Total Earnings over Time</h5>
                <h1>KES. {totalAmount.toLocaleString()}</h1>
              </div>
                <br />
              <div>
                <h5>Withdrawals over Time</h5>
                <h1>KES. 0</h1>
              </div>
            </div>
          </div>
          <div className='earningsRight'>
            <p>Available Balance</p>
            <div className='dashDateCont'>
              <h6>KES. {totalAmount.toLocaleString()}</h6>
            </div> 
            <br />
            <button>Request Payout</button>
            <Link to='/payouts' className='payoutviewlink'>view payouts</Link>
          </div>
        </div>

        <br />
        <h3>My Earnings</h3>
        <br />

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Earning From</th>
              <th>Transaction Reference</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {earnings.length > 0 ? (
              earnings.map((earning) => (
                <tr key={earning.id}>
                  <td>{earning.date}</td>
                  <td>{earning.source}</td>
                  <td>{earning.transRef ? earning.transRef : "Not Applicable"}</td>
                  <td>KES. {earning.amount.toLocaleString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No earnings available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Footer />
    </div>
  );
}

export default Earnings; 
