import React, { useState, useEffect } from 'react'; 
import logo from '../Images/logo.png';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import profpicHolder from '../Images/profimage.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { getAuth, onAuthStateChanged,signOut } from "firebase/auth";
import { app } from '../firebase.js';
import defaultProfilePhoto from '../Images/defprofile.png';
import { getFirestore, collection, query, getDocs, where } from "@firebase/firestore"; 
import Skeleton from 'react-loading-skeleton';  // Make sure Skeleton is imported

function NavBarAccount() {
    const db = getFirestore(app);
    const [loading, setLoading] = useState(true); 
    const [userProfilePicture, setProfilePicture] = useState(defaultProfilePhoto);
    const [loggedInUser, setloggedInUser] = useState(""); 
    const navigate = useNavigate(); // Initialize useNavigate

    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                const userQuery = query(collection(db, "users"), where("userId", "==", uid));

                // Fetch user data from Firestore
                getDocs(userQuery).then((querySnapshot) => {
                    querySnapshot.forEach((docSnapshot) => {
                        const userData = docSnapshot.data();
                        setloggedInUser(userData.fullname);
                        
                        // Check for profile and cover photos in Firebase
                        if (userData.profilePic) {
                            setProfilePicture(userData.profilePic);
                        }
                    });
                    setLoading(false); // Data is fetched, stop loading
                });
            }
        });
    }, [auth, db]);

    function logoutUser(){

        signOut(auth).then(() => {
            // Redirect or navigate to login page after logout
            navigate('/login'); // Change to your desired route
        }).catch((error) => {
            console.error("Error signing out: ", error);
        });

    }

    return (
        <div className='navbar'>
            <Link to='/'>
                <img src={logo} className='logo' alt="" />
            </Link>

            <div className='navbarTwo'>
                <Link to='/profile' className='signuptext'>
                    {loggedInUser}
                </Link>

                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown-toggle">
                        <div className='accountCont'>
                            <FontAwesomeIcon icon={faBars} className="barsIcon"/>
                            <div className='profileCont'>
                                {loading ? (
                                    <Skeleton circle={true} height="100%" width="100%" />
                                ) : (
                                    <img src={userProfilePicture || profpicHolder} alt="Profile" />
                                )}
                            </div>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="/profile">My Account</Dropdown.Item>
                        <Dropdown.Item href="/edit-profile">Edit Account</Dropdown.Item>
                        <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                        <Dropdown.Item href="/earnings">My Earnings</Dropdown.Item>
                        <Dropdown.Item href="/get-verified">Upgrade Account</Dropdown.Item>
                        <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>                 
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default NavBarAccount;
