// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTW9fZaZgtVQ-QFUmHOAMRvs00H-ScuLE",
  authDomain: "amani-touch.firebaseapp.com",
  projectId: "amani-touch",
  storageBucket: "amani-touch.appspot.com",
  messagingSenderId: "805145134646",
  appId: "1:805145134646:web:57841daf367aa595de7a97",
  measurementId: "G-Q85GXT3JP0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {app};
export {analytics}
