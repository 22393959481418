import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom'; // For navigation
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { app } from '../firebase.js';
import axios from 'axios';
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'; 
import { getFirestore, collection, query, getDocs, where,Timestamp, doc, updateDoc, setDoc } from "@firebase/firestore"; 
import { useLocation } from '../LocationContext'; 
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { DailyIframe } from '@daily-co/daily-react';
import Modal from 'react-bootstrap/Modal';


function UpgradeAccount() {

    const auth = getAuth();
    const db = getFirestore(app);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate(); // Hook for navigation

    const [selectedPayment, setSelectedPayment] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formattedPhone, setFormattedPhone] = useState('');
    const [showPayment, setShowPayment] = useState(false); // Control visibility of payment container
    const [showPaymentContainer, setShowPaymentContainer] = useState(false); // Control payment-container visibility
    const [finalAmount, setFinalAmount] = useState('0')
    const [thePaymentCycle, setThePaymentCycle] = useState('')
    const [finalSelectedPackage, setFinalSelectedPackage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loggedInUser, setloggedInUser] = useState(""); 
    const [loggedInEmail, setloggedInEmail] = useState('customer@email.com'); 
    const [loggedInPhoneNumber, setloggedInPhoneNumber] = useState(''); 
    const { location, currency } = useLocation(); 
    const fpPromise = FingerprintJS.load();
    const [loggedInvisitorId, setloggedInvisitorId] = useState();
    const [isPlanActive, setIsPlanActive] = useState(false);
    const [myPlanType, setMyPlanType] = useState(false);  
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [error, setError] = useState('');
    const mpesaNumber = useRef();

    const [theExpirationTimeInSeconds,setexpirationTimeInSeconds ] = useState();
    const [themaxParticipants,setmaxParticipants ] = useState();

    // Get the visitor identifier when you need it.
    fpPromise 
    .then(fp => fp.get())
    .then(result => {
      // This is the visitor identifier:
      const visitorId = result.visitorId;
      setloggedInvisitorId(visitorId)
    });


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setIsLoggedIn(true);
            const uid = user.uid;
            const userQuery = query(collection(db, "users"), where("userId", "==", uid));

            getDocs(userQuery).then((querySnapshot) => {
                if (querySnapshot.empty) {
                    console.log("No matching documents found");
                    return;
                }

                querySnapshot.forEach((docSnapshot) => {
                    const userData = docSnapshot.data();
                    setloggedInUser(userData.fullname);
                    setloggedInPhoneNumber(userData.phone);
                    setloggedInEmail(userData.email);
                    setMyPlanType(userData.planType);

                    const currentDate = new Date();
                    const expiryDate = new Date(userData.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date

                    if (expiryDate > currentDate) {
                    setIsPlanActive(true);
                    } else {
                    setIsPlanActive(false);
                    }

                });
            });
        } else {
            setIsLoggedIn(false);
        }
    });

      // Clean up subscription on unmount
      return () => unsubscribe();
  }, [auth, db]);

    const [billingCycle, setBillingCycle] = useState('monthly');

    const handleToggle = () => {
        setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
    };

    // Get the price based on billing cycle and append the correct currency
    const getPrice = (monthlyPrice, yearlyPrice) => {
      const price = billingCycle === 'monthly' ? monthlyPrice : yearlyPrice;
  
      if (currentCurrencyCode === 'USD') {
          return (price / 122).toFixed(2); // Convert to USD if needed
      }
      return price; // Otherwise, return the price in KES
  };

    function generateTransactionRef(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
    
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
    
        return result;
    } 

    const [loggedInUserIpAddress, setloggedInUserIpAddress] = useState('');

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setloggedInUserIpAddress(data.ip))
            .catch(error => console.error('Error fetching IP address:', error));
    }, []); // The empty array ensures this effect runs only once, on component mount.

    const [currentCurrencyCode, setcurrentCurrencyCode] = useState('USD'); 
    useEffect(() => {
      if (location && currency) { // Ensure both location and currency are ready
          
        if (location.country === "KE"){
          setcurrentCurrencyCode("KES")
        }else{
          setcurrentCurrencyCode("USD")
        }
      }
    }, [location, currency]);

 

  const formatPhoneNumber = (number) => {
    // Strip non-numeric characters
    let cleaned = number.replace(/[^0-9]/g, '');

    // Handle various cases
    if (cleaned.startsWith('0')) {
      cleaned = '254' + cleaned.slice(1); // Replace leading 0 with 254
    } else if (cleaned.startsWith('254')) {
      // No change needed
    } else if (cleaned.startsWith('7')) {
      cleaned = '254' + cleaned; // Add 254 if starts with 7
    } else if (cleaned.startsWith('+254')) {
      cleaned = cleaned.slice(1); // Remove the + sign
    } else if (cleaned.startsWith('1')) {
      cleaned = '254' + cleaned; // For landlines or similar cases starting with 1
    }

    // Ensure the number has 12 digits
    if (cleaned.length === 12 && cleaned.startsWith('254')) {
      return cleaned;
    } else {
      setError('Please enter a valid phone number in the format 254XXXXXXXXX');
      return null;
    }
  };
 

    // Handle package selection
    const handlePackageSelect = (packageName, price) => {

        console.log(`Package selected: ${packageName}`);
        console.log(`Billing cycle: ${billingCycle}`);
        console.log(`Price: KES ${price}`);
        const thefinalPricing = price.toString()
        setFinalAmount(thefinalPricing);
        setFinalSelectedPackage(packageName)
        setThePaymentCycle(billingCycle)
        const amountSentUsd = Math.round(thefinalPricing / 122);

        let maxParticipants = 2;
        let expirationTimeInSeconds = 1820;
        
        if(packageName == "Standard Plan"){
          maxParticipants = 2;
          expirationTimeInSeconds = 1820;

        }else if(packageName == "Premium Plan"){
          maxParticipants = 200;
          expirationTimeInSeconds = 7200;
        }

        setmaxParticipants(maxParticipants);
        setexpirationTimeInSeconds(expirationTimeInSeconds);

        if (!isLoggedIn) {
            // If the user is not signed in, navigate to sign-up page
            navigate('/signup');

        } else {

            if (location.country === "KE"){
              toast.info(`Initiating M-Pesa payment for ${packageName}`);
              handleOpenModal();
            }else{

              
            }    
        }
    };

    //end

  
    async function  upgradeAccountMethod(payMethod,currency, checkReqID, merchID, ){   

        toast.info(`Setting up your account please wait.....`);
        const currentDate = Timestamp.now();
        const transactionRef = generateTransactionRef(7);

        let newExpiryDate;
        let newExpiryDateText;
        let thefinalPricing = finalAmount;
        let packageName = finalSelectedPackage;

        const formatExpiryDate = (date) => {
          const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      
          const day = daysOfWeek[date.getDay()]; // Get the day of the week
          const dayOfMonth = date.getDate(); // Get the day of the month
          const month = monthsOfYear[date.getMonth()]; // Get the month name
          const year = date.getFullYear(); // Get the year
      
          return `${day}, ${dayOfMonth} ${month} ${year}`; // Return formatted string
      };
      
    
        if (billingCycle === 'monthly') {
            let currentDateObj = new Date();
            currentDateObj.setMonth(currentDateObj.getMonth() + 1);
            newExpiryDate = Timestamp.fromDate(currentDateObj);
            newExpiryDateText = formatExpiryDate(currentDateObj); // Format the expiry date

        } else if (billingCycle === 'yearly') {
            let currentDateObj = new Date();
            currentDateObj.setFullYear(currentDateObj.getFullYear() + 1);
            newExpiryDate = Timestamp.fromDate(currentDateObj);
            newExpiryDateText = formatExpiryDate(currentDateObj); // Format the expiry date
        } 


        toast.info(`Setting up video call interface.....`);
        toast.info(`Setting up video call interface.....`);
        const response = await fetch('https://api.daily.co/v1/rooms', {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer dcdedd7d3d8c5b667159c23e9a01e5afe2dcd61a8bada7bbd55ce2960de6530a', // Replace with your Daily.co API key
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              properties: {
                  enable_chat: true,
                  max_participants: themaxParticipants, 
              }
          }),
      });

      if (response.ok) {
          const data = await response.json();
          toast.info(`Setting up video call interface.....`);
          
          const myRoomUrl  = data.url;
          const myRoomName = data.name;

          const newDataRef = doc(collection(db, "Payments"));
          setDoc(newDataRef, {
              //CheckoutRequestID: data.CheckoutRequestID,
              amountPaid: thefinalPricing,
              thefinalPricing:thefinalPricing,
              checkReqID, 
              merchID,
              currency:currency,
              status: 'Paid',
              paymentMethod: payMethod,
              phoneNumber: loggedInPhoneNumber,
              selectedPackage: packageName,
              thePaymentCycle: billingCycle,
              timestamp: currentDate,
              transactionRef,
              userId: auth.currentUser.uid,
              documentId: newDataRef.id,
              loggedInUserIpAddress:loggedInUserIpAddress,
              userPayingFrom:location.country,
              loggedInvisitorId:loggedInvisitorId
              
          }).then(async () => {
              const userid = auth.currentUser.uid;
              toast.info(`Setting up your account please wait.....`);
              // Update the user's planType, paymentCycle, and expiryTime in Firestore
              await updateDoc(doc(db, "users", userid), {
                  upgradeTime: currentDate,
                  paymentCycle: billingCycle,
                  expiryTime: newExpiryDate,
                  planType:packageName,
                  roomUrl:myRoomUrl,
                  roomName:myRoomName,
                  videoCallPerMinute: "100",
                  enableVideoCalls:true
              });
              toast.info(`Setting up your account please wait.....`);
      
              // Send SMS to user
              const smsMessage = `Dear ${loggedInUser}, your account has been successfully upgraded to ${packageName}! Enjoy the enhanced features and benefits. If you have any questions, please contact us. Thank you for being a valued member of AmaniTouch! Next payment is on ${newExpiryDateText}`;
              const smsSent = sendSms(smsMessage, loggedInPhoneNumber);
      
              if (smsSent) {
                  swal('Account Upgraded!', 'Your account has been successfully upgraded!', 'success').then(() => {
                      navigate('/settings');
                  });
              } else {
                  swal('Account Upgraded!', 'Your account has been successfully upgraded!', 'success').then(() => {
                      navigate('/settings');
                  });
              }   
          }); 


      } else {

        const newDataRef = doc(collection(db, "Payments"));
        setDoc(newDataRef, {
            //CheckoutRequestID: data.CheckoutRequestID,
            amountPaid: thefinalPricing,
            thefinalPricing:thefinalPricing,
            currency:currency,
            checkReqID, 
            merchID,
            status: 'Paid',
            paymentMethod: payMethod,
            phoneNumber: loggedInPhoneNumber,
            selectedPackage: packageName,
            thePaymentCycle: billingCycle,
            timestamp: currentDate,
            transactionRef,
            userId: auth.currentUser.uid,
            documentId: newDataRef.id,
            loggedInUserIpAddress:loggedInUserIpAddress,
            userPayingFrom:location.country,
            loggedInvisitorId:loggedInvisitorId
            
        }).then(async () => {
            const userid = auth.currentUser.uid;
            // Update the user's planType, paymentCycle, and expiryTime in Firestore
            await updateDoc(doc(db, "users", userid), {
                upgradeTime: currentDate,
                paymentCycle: billingCycle,
                expiryTime: newExpiryDate,
                planType:packageName,
                roomUrl:"https://amanitouch.daily.co/generalDefault",
                roomName:"generalDefault",
                videoCallPerMinute: "100",
                enableVideoCalls:true
            });
    
            // Send SMS to user
            const smsMessage = `Dear ${loggedInUser}, your account has been successfully upgraded to ${packageName}! Enjoy the enhanced features and benefits. If you have any questions, please contact us. Thank you for being a valued member of AmaniTouch! Next payment is on ${newExpiryDateText}`;
            const smsSent = sendSms(smsMessage, loggedInPhoneNumber);
    
            if (smsSent) {
                swal('Account Upgraded!', 'Your account has been successfully upgraded!', 'success').then(() => {
                    navigate('/settings');
                });
            } else {
                swal('Account Upgraded!', 'Your account has been successfully upgraded!', 'success').then(() => {
                    navigate('/settings');
                });
            }   
        }); 

        throw new Error('Failed to create room: ' + response.statusText);
      }
  }


  function handlePayment(){
    //
    const finalPhone = mpesaNumber.current.value;
    const formattedPhone = formatPhoneNumber(finalPhone);

    if (!formattedPhone) {
      toast.error(`Enter your phone number`);
      return; // Stop if phone number is invalid
    }
    
    console.log(formattedPhone);
    console.log(finalAmount);

    setError(''); // Clear error if phone number is valid
    setIsLoading(true); // Show loading spinner

    sendStkPush(formattedPhone, finalAmount).finally(() => {
      setIsLoading(false); // Hide loading spinner after request completion
    });

  }


  const sendStkPush = async (phone, amount) => {
    try {
      const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/stkpush', {
        phone,
        amount
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      console.log('STK Push Response:', data);

      if (data.ResponseCode === '0') {
        // STK Push sent successfully
        swal({
          title: 'STK Push Sent',
          text: data.CustomerMessage,
          icon: 'success',
          buttons: {
            confirm: {
              text: 'I have paid',
              value: 'confirm',
              className: 'confirm-button'
            },
            cancel: 'I haven\'t paid'
          },
          content: {
            element: 'div',
            attributes: {
              innerHTML: '<style>.confirm-button { position: relative; }</style>'
            }
          }
        }).then((value) => {
          if (value === 'confirm') {
            setIsLoading(true); // Show loading spinner on the "I have paid" button

            verifyPayment(data.CheckoutRequestID).then(() => {
              setIsLoading(false); // Hide loading spinner after payment confirmation
            });
          } else {
            swal('Please complete the payment and try again.');
          }
        });
      } else {
        swal('Failed to send STK Push Request');
      }
    } catch (error) {
      console.error('Error sending STK Push request:', error);
      swal('Error sending STK Push Request. Please try again.');
    }
  };

  const verifyPayment = async (checkoutRequestId) => {
    try {
      const response = await axios.post(
        'https://us-central1-pepea-a9eaa.cloudfunctions.net/api/query',
        {
          queryCode: checkoutRequestId  // Ensure correct field name here
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      const data = response.data;
      console.log('Payment Verification Response:', data);
  
      if (data.ResultCode === '0') {

        const checkReqID = data.CheckoutRequestID;
        const merchID = data.MerchantRequestID;
        const payMethod = "M-Pesa";
        const currency = "KES";

        upgradeAccountMethod(payMethod,currency, checkReqID, merchID);

      } else {
       // swal('Payment Not Verified', 'Your payment could not be verified.', 'error');
       toast.error(`Your payment could not be verified`);
      }

    } catch (error) {
      console.error('Error verifying payment:', error);
      toast.error(`Error verifying payment. Please try again`);
    }
  };


  const sendSms = async (message, phoneNumber) => {
    const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/amanisms/sendsms';
    const requestBody = {
      message,
      phoneNumber
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };


  // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount

 

    return ( 
        <div>
            {isLoggedIn ? <NavBarAccount /> : <NavBar />}

            {!showPaymentContainer && (
            <div className="pricing-plan">
                <h1 className="pricing-title">Choose Your Package</h1>
                <p className="pricing-description">Select a plan that fits your needs and unlock the benefits of AmaniTouch!</p>

                {/* Toggle between Monthly and Yearly billing */}
                <div className="billing-toggle">
                    <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                    <div className="toggle-switch" onClick={handleToggle}>
                        <div className={`toggle-circle ${billingCycle}`}></div>
                    </div>
                    <span className={billingCycle === 'yearly' ? 'active' : ''}>Yearly</span>
                </div>

                <div className="pricing-packages">
                    <div className="pricing-package">
                        <h2 className="package-title">Free Plan</h2>
                        <h3 className="package-price">{currentCurrencyCode} 0</h3>
                        <ul className="package-features">

                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Effortless Account Setup: Get your professional profile up and running in minutes.
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Upload Images and Videos: Showcase your services with photos and videos that speak to your clients.
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Service Listing with Pricing: Display all the services you offer along with clear pricing for easy client selection.
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Showcase Your Availability: Let clients know when you're available by displaying your working hours.
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Direct Client Contact: Allow clients to easily reach you via your listed phone number.
                          </li>

                        </ul>
                        <button className="select-button" disabled={myPlanType === 'Standard Plan' && isPlanActive}>
                            {myPlanType === 'Standard Plan' && isPlanActive ? 'Current Plan' : 'Default Plan'}
                        </button>
                    </div>

                    <div className="pricing-package">
                        <h2 className="package-title">Standard Plan</h2>
                        <h5 className="package-sub-title">Add More Trust and Visibility</h5>
                        <h3 className="package-price">{currentCurrencyCode} {getPrice(950, 950 * 12 * 0.8)}</h3>
                        <ul className="package-features">
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> All Free Plan features included
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Verified Badge: Gain a trust symbol on your profile to attract more clients and build credibility.
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Priority Search Placement: Appear higher in search results, increasing your chances of being discovered by new clients.
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Private Video Calls with Clients: Offer private video sessions with up to 2 participants, perfect for one-on-one consultations. You can set it to be free or paid
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Enhanced Profile Customization: Personalize your profile with advanced options, making it truly stand out.
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Service Analytics Dashboard: Track the performance of your services with an in-depth analytics dashboard to help you improve.
                          </li>
                        </ul>
                        <button
            className="select-button"
            onClick={myPlanType === 'Standard Plan' && isPlanActive ? null : () => handlePackageSelect('Standard Plan', getPrice(950, 950 * 12 * 0.8))}
            disabled={myPlanType === 'Standard Plan' && isPlanActive}
        >
            {myPlanType === 'Standard Plan' && isPlanActive ? 'Current Plan' : myPlanType === 'Standard Plan' && !isPlanActive ? 'Restart Plan' : 'Get Started'}
        </button> 
                    </div>

                    <div className="pricing-package">
                        <h2 className="package-title">Premium Plan</h2>
                        <h5 className="package-sub-title">Maximize Your Earnings and Exposure</h5>
                        <h3 className="package-price">{currentCurrencyCode} {getPrice(3950, 3950 * 12 * 0.8)}</h3>
                        <ul className="package-features">
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> All Free Plan features included
                          </li>
                          <li className="feature-item">
                            <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> All Standard Plan features included
                          </li>
                            <li className="feature-item">
                              <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Access to Premium Clients: Connect with top-tier clients who make recurring bookings or subscribe monthly.
                            </li>
                            <li className="feature-item">
                              <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Optional Booking Management Tools: Automate confirmations and streamline your appointment bookings.
                            </li>
                            <li className="feature-item">
                              <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Secure Payments with Deposits: Enable clients to book your services and pay a 30% deposit upfront, giving you peace of mind (Optional).
                            </li>
                            <li className="feature-item">
                              <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Exclusive Promotions: Get featured prominently on the platform and in our social media, print, and TV campaigns for maximum visibility.
                            </li>
                            <li className="feature-item">
                              <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Ample Private Video Calls: Host private sessions with up to 20 participants—perfect for group consultations. You can set it to be free or paid
                            </li>
                            <li className="feature-item">
                              <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Advanced Video Conferencing Tools: Hold training sessions, conferences, or events with up to 100,000 participants per session—ideal for those looking to scale their business. You can set it to be free or paid
                            </li>
                            <li className="feature-item">
                              <FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Elite Client Access: Gain entry to our exclusive clientele for high-value, long-term opportunities.
                            </li>
                        </ul>
                        <button
            className="select-button"
            onClick={myPlanType === 'Premium Plan' && isPlanActive ? null : () => handlePackageSelect('Premium Plan', getPrice(3950, 3950 * 12 * 0.8))}
            disabled={myPlanType === 'Premium Plan' && isPlanActive}
            
        >
            {myPlanType === 'Premium Plan' && isPlanActive ? 'Current Plan' : myPlanType === 'Premium Plan' && !isPlanActive ? 'Restart Plan' : 'Get Started'}
        </button>
                    </div>
                </div>
            </div>

            
            )}

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Bounce}  
              />
  
            <Footer />

            <Modal show={showModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>M-Pesa Payment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className="text-muted">
                    Please enter your M-Pesa phone number to complete the payment.
                  </p>
                  <div className="form-group mb-3">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="number"
                      id="phoneNumber"
                      className="form-control"
                      ref={mpesaNumber}
                      placeholder="Enter phone number starting with 254"
                    />
                  </div>
                  <p className="text-muted small">
                    You will receive a prompt on your phone to confirm the payment.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>

                <Button onClick={handlePayment} disabled={isLoading} variant="success">
                  {isLoading ? (
                    <>
                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                      <span> Processing...</span>
                    </>
                  ) : (
                    "Make Payment"
                  )}
                </Button>
              </Modal.Footer>
            </Modal> 

        </div>
    );
}

export default UpgradeAccount;
