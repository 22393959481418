import React, { useState, useEffect, useRef } from 'react';
import defaultCoverPhoto from '../Images/bg3.jpg';
import defaultProfilePhoto from '../Images/defprofile.png';
import Form from 'react-bootstrap/Form';
import { useLocation } from "react-router-dom";
import { getFirestore, collection, query, getDocs, where, Timestamp, doc, updateDoc, setDoc  } from "@firebase/firestore"; 
import { getDatabase, ref, onValue, onDisconnect, set } from 'firebase/database';
import { app } from '../firebase.js'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import swal from 'sweetalert';
import { useLocation as useCustomLocation } from '../LocationContext';
import { useNavigate, Link } from 'react-router-dom'; 
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';


function StartAcall() {
    const thelocation = useLocation();
    const userId = thelocation.search.substring(1);
    let navigate = useNavigate();
    const [loggedInUser, setLoggedInUser] = useState("");
    const [loggedInEmail, setLoggedInEmail] = useState("");
    const [userProfilePicture, setProfilePicture] = useState(defaultProfilePhoto); 
    const [userCoverPicture, setCoverPicture] = useState(defaultCoverPhoto);
    const [aboutMe, setAboutMe] = useState("");
    const [currencyCode, setCurrencyCode] = useState(''); 
    const [videoCallPerMinute, setVideoCallPerMinute] = useState('');
    const [planType, setPlanType] = useState('');
    const [isPlanActive, setIsPlanActive] = useState(false);
    const [loading, setLoading] = useState(true);
    const [minutes, setMinutes] = useState(1); 
    const { location, currency } = useCustomLocation();
    const db = getFirestore(app);
    const fpPromise = FingerprintJS.load();
    const [currentCurrencyCode, setcurrentCurrencyCode] = useState('KES');
    const [displayedCurrencyCode, setDisplayedCurrencyCode] = useState('KES');
    const [displayedVideoCallPerMinute, setDisplayedVideoCallPerMinute] = useState(0);
    const [userPhone, setUserPhone] = useState('');
    const [mycallerCountry, setmycallerCountry] = useState('Kenya')
    const [myRoomUrl, setmyRoomUrl] = useState('')
    const [myRoomName, setmyRoomName] = useState('')
    const [onlineStatus,setOnlineStatus ] = useState('');

    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [isLoading, setIsLoading] = useState(false); 
    const mpesaNumber = useRef();

     

    const [error, setError] = useState('');

    useEffect(() => {
        if (location && currency) {

            if (currency.code == "KES"){

                setcurrentCurrencyCode("KES");
                setDisplayedCurrencyCode("KES" === 'KES' ? 'KES' : 'USD');
    
                // Convert to USD if the currency is not KES (example conversion rate: 1 KES = 0.007 USD)
                const conversionRate = 0.0078;
                setDisplayedVideoCallPerMinute(
                    "KES" === 'KES' ? videoCallPerMinute : (videoCallPerMinute * conversionRate).toFixed(2)
                );

            }else{

                setcurrentCurrencyCode("USD");
                setDisplayedCurrencyCode("USD" === 'KES' ? 'KES' : 'USD');
    
                // Convert to USD if the currency is not KES (example conversion rate: 1 KES = 0.007 USD)
                const conversionRate = 0.0078;
                setDisplayedVideoCallPerMinute(
                    "USD" === 'KES' ? videoCallPerMinute : (videoCallPerMinute * conversionRate).toFixed(2)
                );

            }

            setmycallerCountry(currency.countryName)

        }
    }, [location, currency, videoCallPerMinute]); 


    useEffect(() => {
        const db = getDatabase(app);

        const userStatusRef = ref(db, `/status/${userId}`);
    
        // Listen for real-time updates to the user's status
        const unsubscribe = onValue(userStatusRef, (snapshot) => {
            const status = snapshot.val();
            if (status) {
            setOnlineStatus(status.state);
            }
        });
  
        // Cleanup the listener when the component unmounts
        return () => unsubscribe();
        
    }, [app]);
    
 

    const getFirstName = (name) => {
        return name.split(" ")[0]; // Split by space and take the first element
    };

    
    useEffect(() => {
        const uid = userId;
        const userQuery = query(collection(db, "users"), where("userId", "==", uid));
        
        getDocs(userQuery).then((querySnapshot) => {
            querySnapshot.forEach((docSnapshot) => {
                const userData = docSnapshot.data();
                setLoggedInUser(userData.fullname);
                setAboutMe(userData.aboutMe);
                setCurrencyCode(userData.currencyCode);
                setVideoCallPerMinute(userData.videoCallPerMinute);
                setPlanType(userData.planType); 
                setUserPhone(userData.phone); 
                setmyRoomUrl(userData.roomUrl);
                setmyRoomName(userData.roomName);
                setLoggedInEmail(userData.email);

                const currentDate = new Date();
                const expiryDate = new Date(userData.expiryTime.seconds * 1000);
                setIsPlanActive(expiryDate > currentDate);

                if (userData.profilePic) setProfilePicture(userData.profilePic);
                if (userData.coverPhoto) setCoverPicture(userData.coverPhoto);

                // Check if the account is ineligible for video calls
                if (!(expiryDate > currentDate) || !(userData.planType === 'Standard Plan' || userData.planType === 'Premium Plan')) {
                    swal("Account Ineligible", "This account doesn't support video calls", "warning").then(()=>{
                        navigate('/');
                    })
                }
                
            });
            setLoading(false);
        }); 
    }, [db, userId]);

    // Handler to set minutes within the range of 10-60
    const handleMinutesChange = (e) => {
        let value = parseInt(e.target.value);
        if (value < 1) value = 10;
        if (value > 60) value = 60;
        setMinutes(value);


    };

    // Calculate total amount to pay based on minutes and rate
    const videoRate = parseFloat(videoCallPerMinute || 0);
    const totalPayment = minutes * (currentCurrencyCode === 'KES' ? videoRate : videoRate * 0.0078); // Assuming 1 KES = 0.007 USD for example

    function generateTransactionRef(length) {
        const characters = '0123456789';
        let result = '';
    
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
    
        return result;
    } 

    const [loggedInUserIpAddress, setloggedInUserIpAddress] = useState('');
    const [loggedInvisitorId, setloggedInvisitorId] = useState();

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setloggedInUserIpAddress(data.ip))
            .catch(error => console.error('Error fetching IP address:', error));
    }, []); // The empty array ensures this effect runs only once, on component mount.

    // Get the visitor identifier when you need it.
    fpPromise 
    .then(fp => fp.get())
    .then(result => {
      // This is the visitor identifier:
      const visitorId = result.visitorId;
      setloggedInvisitorId(visitorId)
    });

 

    // Function to log minutes on button click 
    function payVideCall() {

        if (minutes < 10){
            swal("Info", "The minimum number of minutes is 10", "info");
            

        }else{
            if (onlineStatus === "offline"){
                //swal("Info", "The user is currenly offline try again another time", "info");

                swal({
                    title: "Info",
                    text: "The user is currently offline at this time. Notify the user to come online for a video call? The user will be notified via Text and Email.",
                    icon: "info",
                    buttons: {
                      confirm: {
                        text: "Notify User to Come Online",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                      },
                      cancel: {
                        text: "Cancel",
                        value: false,
                        visible: true,
                        className: "",
                        closeModal: true,
                      }
                    },
                    dangerMode: true,
                  })
                  .then((willNotify) => {
                    if (willNotify) {
                        //send notification here
                        toast.info(`Sending a request.....`);

                        // Send SMS to the user that a booking has been made
                        const smsMessage = `Dear ${loggedInUser}, you have a pending video call request. Please come online to connect and continue the conversation. Thank you!`;
                        const smsSent = sendSms(smsMessage, userPhone);
                
                        if (smsSent) {
                            sendReminderEmail();
                        } else {
                            sendReminderEmail();
                        } 
                    } else {
                      swal("No notification sent.");
                    }
                  });
                   

            }else if (onlineStatus === "on call"){
              swal("Info", "The user is currenly on call, kindly try again in a moment", "info");
            }else{
              handleOpenModal();
            }
        }
    }

    function sendReminderEmail(){
        const sendSubject = "Connection Request for Video Call";

         const htmlEmailContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Online Session Request</title>
            </head>
            <body style="font-family: Arial, sans-serif; background-color: #f2f2f2; margin: 0; padding: 20px; color: #333;">
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr>
                <td align="center">
                    <table width="600" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-radius: 8px; padding: 20px;">
                    <tr>
                        <td align="center" style="padding-bottom: 20px;">
                        <h2 style="color: #5a8d9d; font-size: 24px;">New Session Request on AmaniTouch</h2>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 20px; font-size: 16px; line-height: 1.6; color: #333;">
                        <p>Hi ${loggedInUser},</p>
                        <p>You have a new request from a user on AmaniTouch, who would like to connect with you for a session.</p>
                        <p>To proceed, please log into your account and come online at your earliest convenience. This will allow you to connect with the user and begin your session.</p>
                        <p>If you’re currently unavailable, you can ignore this email.</p>
                        <p>We look forward to helping you create a great experience for your clients on AmaniTouch!</p>
                        <p>Warm regards,</p>

                        <p>Lindsey<br>
                           AmaniTouch</p>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="padding: 20px;">
                        <a href="http://amanitouch.app/profile" style="display: inline-block; padding: 12px 24px; color: #ffffff; background-color: #5a8d9d; border-radius: 5px; text-decoration: none; font-size: 16px;">
                            Go Online Now
                        </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 20px; font-size: 12px; color: #999; text-align: center;">
                        <p>&copy; 2024 AmaniTouch. All rights reserved.</p>
                        </td>
                    </tr>
                    </table>
                </td>
                </tr>
            </table>
            </body>
            </html>

         `;

        const emailData = {
            email: loggedInEmail,
            message: htmlEmailContent, // Sending the HTML content
            subject: sendSubject
          };
        
          fetch('https://us-central1-pepea-a9eaa.cloudfunctions.net/amanicommapi/send-email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(emailData)
          })
            .then(response => response.json())
            .then(data => {
              console.log('Email response:', data);
              toast.success(`Request sent successfully`);
              swal("Notification sent!", {
                icon: "success",
              });

            })
          .catch(error => {
          console.error('Error sending email:', error);
          toast.success(`Request sent successfully`);
                swal("Notification sent!", {
                    icon: "success",
                });
  
          });

    }

    const formatPhoneNumber = (number) => {
      // Strip non-numeric characters
      let cleaned = number.replace(/[^0-9]/g, '');
  
      // Handle various cases
      if (cleaned.startsWith('0')) {
        cleaned = '254' + cleaned.slice(1); // Replace leading 0 with 254
      } else if (cleaned.startsWith('254')) {
        // No change needed
      } else if (cleaned.startsWith('7')) {
        cleaned = '254' + cleaned; // Add 254 if starts with 7
      } else if (cleaned.startsWith('+254')) {
        cleaned = cleaned.slice(1); // Remove the + sign
      } else if (cleaned.startsWith('1')) {
        cleaned = '254' + cleaned; // For landlines or similar cases starting with 1
      }
  
      // Ensure the number has 12 digits
      if (cleaned.length === 12 && cleaned.startsWith('254')) {
        return cleaned;
      } else {
        setError('Please enter a valid phone number in the format 254XXXXXXXXX');
        return null;
      }
    };

    function handlePayment(){
      //
      const finalPhone = mpesaNumber.current.value;
      const formattedPhone = formatPhoneNumber(finalPhone);

      const finalAmount = totalPayment;
  
      if (!formattedPhone) {
        toast.error(`Enter your phone number`);
        return; // Stop if phone number is invalid
      }
    
      setError(''); // Clear error if phone number is valid
      setIsLoading(true); // Show loading spinner
  
      sendStkPush(formattedPhone, finalAmount).finally(() => {
        setIsLoading(false); // Hide loading spinner after request completion
      });
  
    }


  const sendStkPush = async (phone, amount) => {
    try {
      const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/stkpush', {
        phone,
        amount
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      console.log('STK Push Response:', data);

      if (data.ResponseCode === '0') {
        // STK Push sent successfully
        swal({
          title: 'STK Push Sent',
          text: data.CustomerMessage,
          icon: 'success',
          buttons: {
            confirm: {
              text: 'I have paid',
              value: 'confirm',
              className: 'confirm-button'
            },
            cancel: 'I haven\'t paid'
          },
          content: {
            element: 'div',
            attributes: {
              innerHTML: '<style>.confirm-button { position: relative; }</style>'
            }
          }
        }).then((value) => {
          if (value === 'confirm') {
            setIsLoading(true); // Show loading spinner on the "I have paid" button

            verifyPayment(data.CheckoutRequestID).then(() => {
              setIsLoading(false); // Hide loading spinner after payment confirmation
            });
          } else {
            swal('Please complete the payment and try again.');
          }
        });
      } else {
        swal('Failed to send STK Push Request');
      }
    } catch (error) {
      console.error('Error sending STK Push request:', error);
      swal('Error sending STK Push Request. Please try again.');
    }
  };

  const verifyPayment = async (checkoutRequestId) => {
    try {
      const response = await axios.post(
        'https://us-central1-pepea-a9eaa.cloudfunctions.net/api/query',
        {
          queryCode: checkoutRequestId  // Ensure correct field name here
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      const data = response.data;
      console.log('Payment Verification Response:', data);
  
      if (data.ResultCode === '0') {

        const checkReqID = data.CheckoutRequestID;
        const merchID = data.MerchantRequestID;
        const payMethod = "M-Pesa";

        makeaBooking(checkReqID,merchID,payMethod);

       // upgradeAccountMethod(payMethod,currency, checkReqID, merchID);

      } else {
       // swal('Payment Not Verified', 'Your payment could not be verified.', 'error');
       toast.error(`Your payment could not be verified`);
      }

    } catch (error) {
      console.error('Error verifying payment:', error);
      toast.error(`Error verifying payment. Please try again`);
    }
  };


    function makeaBooking(checkReqID,merchID,payMethod ){
       
        toast.info(`Configuring a room.....`);
        const currentDate = Timestamp.now();
        const theRoomID = generateTransactionRef(5);
        //console.log(theRoomID);
        const sendEmail = loggedInEmail;
        const callerCountry =  mycallerCountry;
        const currency = currentCurrencyCode;
        
        

        const newDataRef = doc(collection(db, "videoCallPayments"));
          setDoc(newDataRef, {
              currency: currency,
              totalPayment:totalPayment,
              minutes:minutes,
              status: 'Paid',
              callerCountry: callerCountry,
              timestamp: currentDate,
              theRoomID,
              documentId: newDataRef.id,
              callerUserIpAddress:loggedInUserIpAddress,
              callerrPayingFrom:location.country,
              callerVisitorId:loggedInvisitorId,
              payeeID:userId,
              checkReqID,
              merchID,
              payMethod
          }).then(async () => {
              sendNotifUsermail(sendEmail);
              toast.info(`please wait.....`);
              const newCallRef = doc(collection(db, "videoCall"));
              setDoc(newCallRef, {
                callDuration:minutes,
                callerCountry: callerCountry,
                timestamp: currentDate,
                theRoomID,
                documentId: newDataRef.id,
                callerIpAddress:loggedInUserIpAddress,
                callerFrom:location.country,
                callerVisitorId:loggedInvisitorId,
                hostUserID:userId,
                roomUrl:myRoomUrl,
                roomName:myRoomName
            })

              toast.info(`please wait.....`);
              //send email to the user
      
              // Send SMS to the user that a booking has been made
              const smsMessage = `Dear ${loggedInUser}, a user has scheduled a ${minutes}-minute video call session with you and is currently online. Please join the session promptly to avoid keeping the user waiting. Thank you.`;
              const smsSent = sendSms(smsMessage, userPhone);
      
              if (smsSent) {
                startAnewVideoCall(myRoomUrl,minutes);
              } else {
                startAnewVideoCall(myRoomUrl,minutes);
              } 
            }); 
    }


    const sendSms = async (message, phoneNumber) => {
        const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/amanisms/sendsms';
        const requestBody = {
          message,
          phoneNumber
        };
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
    
          if (response.ok) {
            const responseData = await response.json();
            console.log(responseData);
            return true;
          } else {
            console.error(`HTTP error! Status: ${response.status}`);
            return false;
          }
        } catch (error) {
          console.error('Error:', error);
          return false;
        }
    };

    

    function startAnewVideoCall(myRoomUrl, minutes) {
        // Navigate to oncall with all required data
        navigate('/oncall', { 
          state: { 
            myRoomUrl: myRoomUrl,
            minutes: minutes
          } 
        });
    }

    function sendNotifUsermail(sendEmail) {

        const sendSubject = "Video Call Session Notification";
      
        // HTML Email content
        const htmlEmailContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Video Call Session Notification</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        background-color: #f4f4f4;
                        margin: 0;
                        padding: 20px;
                    }
                    .container {
                        background-color: #ffffff;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                    }
                    h2 {
                        color: #333333;
                    }
                    p {
                        color: #555555;
                        line-height: 1.5;
                    }
                    .footer {
                        margin-top: 20px;
                        font-size: 0.9em;
                        color: #777777;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h2>Video Call Session Notification</h2>
                    <p>Dear ${loggedInUser},</p>
                    <p>We hope this message finds you well.</p>
                    <p>We would like to inform you that a user has scheduled a <strong>${minutes}-minute</strong> video call session with you and is currently online, awaiting your presence. To join the video call, please follow these steps:</p>
                    <ol>
                        <li>Log in to your account.</li>
                        <li>Navigate to the <strong>My Account</strong> section.</li>
                        <li>Click on the <strong>Video Call</strong> icon located next to your username.</li>
                    </ol>
                    <p>Please note that the user will be ejected from the room once the session concludes.</p>
                    <p>Thank you for your attention to this matter, and we appreciate your promptness in joining the call.</p>
                    <div class="footer">
                        <p>Best regards,</p>
                        <p>Lindsey<br>
                        AmaniTouch<br>
                    </div>
                </div>
            </body>
            </html>

        `;
      
        // Email data
        const emailData = {
          email: sendEmail,
          message: htmlEmailContent, // Sending the HTML content
          subject: sendSubject
        };
      
        fetch('https://us-central1-pepea-a9eaa.cloudfunctions.net/amanicommapi/send-email', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(emailData)
        })
          .then(response => response.json())
          .then(data => {
            console.log('Email response:', data);
            
    
          })
        .catch(error => {
        console.error('Error sending email:', error);
        

        });
      }
      


    return (
      <div className='startAcall'>
        <div className='startAcallOpacity'></div>
        <div className='startAcallContent'>
            <div className='startAcallContentDiv'>
                {loading ? (
                    <Skeleton height="100%" />
                ) : (
                    <>
                        <div className='accSetupProfCont clearfix'>
                            <div className='accSetupCover2'>
                                <img src={userCoverPicture} alt="Cover" />
                            </div>
                            <div className='accSetupProfile2'>
                                <img src={userProfilePicture} alt="Profile" />
                            </div>
                            <div className='accSetupTexts2'>
                                <h3>
                                    {loggedInUser} 
                                    {(isPlanActive && (planType === 'Standard Plan' || planType === 'Premium Plan')) && (
                                        <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtnLive" />
                                    )}
                                </h3>
                                <p>Video call per minute {displayedCurrencyCode}.{displayedVideoCallPerMinute} 
                                <span
                                  className={
                                    onlineStatus === 'online'
                                      ? 'spanonline'
                                      : onlineStatus === 'on call'
                                      ? 'spanoncall'
                                      : 'spanoffline'
                                  }
                                >
                                  {onlineStatus === 'online'
                                    ? 'online'
                                    : onlineStatus === 'on call'
                                    ? 'on call'
                                    : 'offline'}
                                </span>
                           
                                </p>
                            </div>
                        </div>

                        <div className='startAcallContentBotton'>
                            <div className='alert alert-primary cuzAlert'>
                                <p className='alertPr2'>{aboutMe}</p>
                            </div>

                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                                <Form.Label className='cusCallLabel'>Enter Number of minutes</Form.Label> 
                                <Form.Control 
                                    type='number' 
                                    min="10" 
                                    max="60" 
                                    value={minutes}
                                    onChange={handleMinutesChange}
                                    placeholder='Enter number of minutes' 
                                /> 
                            </Form.Group>

                            <p>Pay {displayedCurrencyCode} {(totalPayment).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                        <button className='startAcallContentButton' onClick={payVideCall}>
                            Pay and video call {getFirstName(loggedInUser)}
                        </button>
                    </>
                )}
            </div>
        </div>

        <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Bounce}  
              />

<Modal show={showModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>M-Pesa Payment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className="text-muted">
                    Please enter your M-Pesa phone number to complete the payment.
                  </p>
                  <div className="form-group mb-3">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="number"
                      id="phoneNumber"
                      className="form-control"
                      ref={mpesaNumber}
                      placeholder="Enter phone number starting with 254"
                    />
                  </div>
                  <p className="text-muted small">
                    You will receive a prompt on your phone to confirm the payment.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>

                <Button onClick={handlePayment} disabled={isLoading} variant="success">
                  {isLoading ? (
                    <>
                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                      <span> Processing...</span>
                    </>
                  ) : (
                    "Make Payment"
                  )}
                </Button>
              </Modal.Footer>
            </Modal> 
      </div>
    );
};

export default StartAcall;
 