import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import './Responsive.css';
import Home from './Pages/Home';
import ViewUser from './Pages/ViewUser';
import SignUp from './Pages/SignUp';
import Register from './Pages/Register';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import ResetPassword from './Pages/ResetPassword';
import Login from './Pages/Login';
import AccSetUp from './Pages/AccSetUp';
import AccountSetuPul from './Pages/AccountSetuPul';
import ServicesSetup from './Pages/ServicesSetup';
import Profile from './Pages/Profile';
import EditProfile from './Pages/EditProfile';
import AboutUs from './Pages/AboutUs';
import HelpCentre from './Pages/HelpCentre';
import NewsUpdates from './Pages/NewsUpdates';
import CommunityGuidelines from './Pages/CommunityGuidelines';
import TermsOfService from './Pages/TermsOfService';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Faqs from './Pages/Faqs';
import SafetyPage from './Pages/SafetyPage';
import RefundPolicy from './Pages/RefundPolicy';
import UpgradeAccount from './Pages/UpgradeAccount';
import { LocationProvider } from './LocationContext';
import ViewMore from './Pages/ViewMore';
import StartAcall from './Pages/StartAcall';
import { getDatabase, ref, onDisconnect, set } from "firebase/database";
import { app } from './firebase.js';
import OnVideoCall from './Pages/OnVideoCall.js';
import MakeAcall from './Pages/MakeAcall.js';
import AccountSettings from './Pages/AccountSettings.js';
import TestComponents from './Pages/TestComponents.js';
import Earnings from './Pages/Earnings.js';

function AppWrapper() {
  const auth = getAuth();
  const location = useLocation();

  useEffect(() => {
    const db = getDatabase(app);

    // Listen for changes in authentication state
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const userStatusRef = ref(db, `/status/${userId}`);

        // Define different statuses
        const onlineStatus = {
          state: "online",
          last_changed: Date.now(),
          userId: userId,
        };

        const onCallStatus = {
          state: "on call",
          last_changed: Date.now(),
          userId: userId,
        };

        const offlineStatus = {
          state: "offline",
          last_changed: Date.now(),
          userId: userId,
        };

        // Set user as offline automatically when disconnected
        onDisconnect(userStatusRef).set(offlineStatus);

        // Update status based on the current route
        const updateStatus = (pathname) => {
          if (pathname === "/make-a-call") {
            set(userStatusRef, onCallStatus); // Set status to "on call"
          } else {
            set(userStatusRef, onlineStatus); // Set status to "online"
          }
        };

        // Call the function immediately for the initial route
        updateStatus(location.pathname);

        // Monitor location changes
        return () => {
          set(userStatusRef, offlineStatus); // Set status to offline on unmount
        };
      }
    });
  }, [auth, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/view-user" element={<ViewUser />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/account-set-up" element={<AccountSetuPul />} />
      <Route path="/services-set-up" element={<ServicesSetup />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/help-centre" element={<HelpCentre />} />
      <Route path="/news-and-updates" element={<NewsUpdates />} />
      <Route path="/community-guidelines" element={<CommunityGuidelines />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/safety-and-security" element={<SafetyPage />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/get-verified" element={<UpgradeAccount />} />
      <Route path="/view-more" element={<ViewMore />} />
      <Route path="/call" element={<StartAcall />} />
      <Route path="/oncall" element={<OnVideoCall />} />
      <Route path="/make-a-call" element={<MakeAcall />} />
      <Route path="/settings" element={<AccountSettings />} />
      <Route path="/earnings" element={<Earnings />} />
      <Route path="/test-env" element={<TestComponents />} />
      <Route path="/payouts" element={<Earnings />} />
      
    </Routes>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <LocationProvider>
          <AppWrapper />
        </LocationProvider>
      </Router>
    </div>
  );
}

export default App;