import React, { useState } from 'react';
import logo from '../Images/logo.png';
import { useNavigate, Link } from 'react-router-dom';

function SignUp() {
  const navigate = useNavigate();
  const [days, setDays] = useState(7); // Initial number of days
  const ratePerDay = 5000; // Earnings per day

  // Calculate earnings based on the selected number of days
  const totalEarnings = days * ratePerDay;

  const handleSliderChange = (e) => {
    setDays(e.target.value);
  };

  function startSignUp() {
    navigate('/register');
  }

  return (
    <div className='signUpContainer'>
      <div className='signUpContainer1'>
        <Link to='/'>
          <img src={logo} alt="Ubunifu Logo" className='logoSignUp' />
        </Link>
        <h1>Join as a Massage Therapist</h1>
        <h3>Potential Earnings</h3>
        <h2>KES. {totalEarnings.toLocaleString()}</h2>
        <p>
          <span className='spanDays'>{days} days</span> at KES. {ratePerDay.toLocaleString()} per day
        </p>

        <input
          type="range"
          name="incomeEstimate"
          id="incomeEstimate"
          min="1"
          max="30"
          value={days}
          onChange={handleSliderChange}
          className="incomeSlider"
        />

        <button className="ctaButton" onClick={startSignUp}>
          Start Your Journey
        </button>
      </div>
      <div className='signUpContainer2'></div>
    </div>
  );
}

export default SignUp;
