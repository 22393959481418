import React, { useRef, useState } from 'react';
import './Auth.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, Link } from 'react-router-dom';
import swal from 'sweetalert';
import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import logo from '../Images/logo.png';

function ResetPassword() {
    let navigate = useNavigate();
    const emailRef = useRef();
  
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
  
    async function loginUser() {
      const email = emailRef.current.value;

  
      setShowProgress(true);
      setShowSignInbtn(false);
  
      const auth = getAuth();

        sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
            setShowProgress(false);
            setShowSignInbtn(true);

            swal({
                title: "Emai Sent!",
                text: "Please check your email for Password reset link",
                icon: "success",
                button: "Okay",
              }).then((value) => {
                navigate('/');
              });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setShowProgress(false);
            setShowSignInbtn(true);
            swal('An Error occured!', '' + errorMessage + '', 'error');
            // ..
        });

  
    }
  
    return (
      <div className='mainAuth'>
        <div className='AuthLeft'>
          <div className='authleftCont'>
            <video
              autoPlay
              muted
              loop
              src='https://res.cloudinary.com/ubunifu/video/upload/v1727119071/6186707-uhd_3840_2160_25fps_g2j9sg.mp4'
            ></video>
            <div className='authLeftOpacity'></div>
          </div>
        </div>
  
        <div className='AuthRight'>
        <Link to='/'> 
          <img src={logo} alt='' className='logoAuth' />
        </Link>

          <h3>Reset Password</h3>
          <br />
  
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Email address</Form.Label>
            <Form.Control type='email' placeholder='Enter email' ref={emailRef} />
          </Form.Group>
  
          {showSignInbtn && (
            <Button variant='primary' type='submit' className='btnLogin' onClick={loginUser}>
              Reset Password
            </Button>
          )}
  
          {showProgress && (
            <Button variant='dark' disabled className='btnLogin'>
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              <span> Please wait</span>
            </Button>
          )}
  
          <Link to='/login' className='signuptext '>
            Already have an Account? Login
          </Link>
        </div>
      </div>
    );
  }
  

export default ResetPassword