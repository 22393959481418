import React, { useState, useRef, useEffect } from 'react';
import './Auth.css';
import NavBarAccount from '../Components/NavBarAccount';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, getDocs, where, doc, updateDoc } from "@firebase/firestore";
import { app } from '../firebase.js';
import defaultCoverPhoto from '../Images/bg3.jpg';
import defaultProfilePhoto from '../Images/defprofile.png';
import swal from 'sweetalert';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


function AccountSettings() {
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
    const [loggedInUser, setLoggedInUser] = useState("");
    const [userProfilePicture, setProfilePicture] = useState(defaultProfilePhoto);
    const [userCoverPicture, setCoverPicture] = useState(defaultCoverPhoto);
    const [userLocation, setUserLocation] = useState("Nairobi, Kenya");
    const [planType, setPlanType] = useState('');
    const [isPlanActive, setIsPlanActive] = useState(false);
    const [currencyCode, setCurrencyCode] = useState('');
    const [currencyName, setCurrencyName] = useState('');

    const [enableVideoCalls, setEnableVideoCalls] = useState(true);
    const [displayPhoneNumber, setDisplayPhoneNumber] = useState(true);
    const [chargeForPhoneNumber, setChargeForPhoneNumber] = useState(false);
    const [displayPrivacy, setDisplayPrivacy] = useState(false);

    const mpesaNumberRef = useRef();
    const videoCallPerMinuteRef = useRef();
    const [mpesaNumber, setMpesaNumber] = useState('');
    const [videoCallPerMinute, setVideoCallPerMinute] = useState('');
    const [theUser, setTheUser] = useState();

    const db = getFirestore(app);
    const auth = getAuth();
    const navigate = useNavigate();
 
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
              setTheUser(user.uid);
              const userQuery = query(collection(db, "users"), where("userId", "==", user.uid));
              getDocs(userQuery).then((querySnapshot) => {
                  querySnapshot.forEach((docSnapshot) => {
                      const userData = docSnapshot.data();
                      
                      // Set user data to state
                      setLoggedInUser(userData.fullname);
                      setUserLocation(userData.userLocation);
                      setCurrencyCode(userData.currencyCode);
                      setCurrencyName(userData.currencyName);
                      setMpesaNumber(userData.mpesaNumber);
                      setVideoCallPerMinute(userData.videoCallPerMinute);
                      setPlanType(userData.planType);
                      
                      // Set toggle states
                      setEnableVideoCalls(userData.enableVideoCalls || false);
                      setDisplayPhoneNumber(userData.displayPhoneNumber || false);
                      setChargeForPhoneNumber(userData.chargeForPhoneNumber || false);
                      setDisplayPrivacy(userData.displayPrivacy || false); // Add this line for displayPrivacy
  
                      // Plan expiration check
                      const currentDate = new Date();
                      const expiryDate = new Date(userData.expiryTime.seconds * 1000);
                      setIsPlanActive(expiryDate > currentDate);
  
                      // Set profile and cover pictures
                      if (userData.profilePic) setProfilePicture(userData.profilePic);
                      if (userData.coverPhoto) setCoverPicture(userData.coverPhoto);
                  });
              }).catch((error) => {
                  console.error("Error fetching user data:", error);
                  swal("Error", "Failed to fetch user data: " + error.message, "error");
              });
          } else {
              navigate("/");
          }
      });
  
      return () => unsubscribe();
  }, [auth, db, navigate]);
  

  const saveChanges = async () => {
    if (!theUser) {
        swal("Error", "User not logged in", "error");
        return;
    }

    if (!(isPlanActive && (planType === 'Standard Plan' || planType === 'Premium Plan'))) {
        swal("Error", "You need an active Standard or Premium plan to save changes.", "error");
        return;
    }
    
    setShowProgress(true);
    setShowSignInbtn(false);

    try {
        const userDocRef = doc(db, "users", theUser);

        // Prepare updated data object
        const updatedData = {
            mpesaNumber: mpesaNumberRef.current?.value || mpesaNumber,
            videoCallPerMinute: videoCallPerMinuteRef.current?.value || videoCallPerMinute,
            enableVideoCalls: enableVideoCalls,
            displayPhoneNumber: displayPhoneNumber,
            chargeForPhoneNumber: chargeForPhoneNumber,
            displayPrivacy: displayPrivacy, // Save privacy setting
        };

        // If privacy is enabled, update isUserVerified to "private"
        if (displayPrivacy) {
            updatedData.isuserVerified = "private";
        }else{
            updatedData.isuserVerified = "yes";
        }

        // Update Firestore document
        await updateDoc(userDocRef, updatedData);

        swal("Success", "Changes saved successfully!", "success");
    } catch (error) {
        swal("Error", "Failed to save changes: " + error.message, "error");
    } finally {
        setShowProgress(false);
        setShowSignInbtn(true);
    }
};


    const getVerified = () => navigate("/get-verified");

    return (
        <div>
            <NavBarAccount />
            <div className="profPage">
                <div className="profTopCover clearfix">
                    <div className="profileHeader">
                        <img src={userCoverPicture} alt="Cover" />
                    </div>
                    <div className="viewProfPicCont">
                        <img src={userProfilePicture} alt="Profile" />
                    </div>
                    <div className="profNameCont">
                        <div>
                            <h3>
                                {loggedInUser}
                                {isPlanActive && (planType === 'Standard Plan' || planType === 'Premium Plan') ? (
                                    <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtn" />
                                ) : (
                                    <span className="getverifiedSpan" onClick={getVerified}>Get Verified</span>
                                )}
                            </h3>
                            <p><FontAwesomeIcon icon={faMapMarkerAlt} className="mapIcon" /> {userLocation}</p>
                        </div>
                    </div>
                </div>

                <div className="profBottomCover2 bottomPageEdit">
                    <h3>Premium Account Settings</h3>
                    <br />

                    {/* Video Calls Section */}
                    <div className="card text-align-left">
                        <h4>Video Calls</h4>
                        <br />
                        <Form.Check
                            type="switch"
                            id="video-calls"
                            label="Enable video calls"
                            checked={enableVideoCalls}
                            onChange={() => setEnableVideoCalls(!enableVideoCalls)}
                        />
                        <br />
                        <Form.Group md="4" controlId="validationCustomUsername">
                            <Form.Label>Video call per minute amount in {currencyName}</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">{currencyCode}</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    placeholder="amount"
                                    aria-describedby="inputGroupPrepend"
                                    defaultValue={videoCallPerMinute}
                                    ref={videoCallPerMinuteRef}
                                />
                            </InputGroup>
                        </Form.Group>
                        <br />
                        <Alert variant="primary">
                            <p>AmaniTouch charges 20% of all transactions. You can request withdrawal once your balance reaches 1,000 {currencyName}.</p>
                        </Alert>
                    </div>
                    <br />

                    {/* Phone Calls Section */}
                    <div className="card text-align-left">
                        <h4>Phone Calls</h4>
                        <br />
                        <Form.Check
                            type="switch"
                            id="display-phone"
                            label="Display my phone number publicly"
                            checked={displayPhoneNumber}
                            onChange={() => setDisplayPhoneNumber(!displayPhoneNumber)}
                        />
                        <Form.Check
                            type="switch"
                            id="charge-phone"
                            label="Charge a token to view my phone number"
                            checked={chargeForPhoneNumber}
                            onChange={() => setChargeForPhoneNumber(!chargeForPhoneNumber)}
                        />
                        <br />
                        <Alert variant="primary">
                            <p>Users will be charged KES 100 to view your phone number if you charge a token. AmaniTouch charges 20% of all transactions.</p>
                        </Alert>
                    </div>
                    <br />

                    
                    {/* Payouts Section */}
                    <div className="card text-align-left">
                        <h4>Payouts</h4>
                        <br />
                        <Form.Group md="4" controlId="validationCustomUsername">
                            <Form.Label>M-Pesa Phone number to receive payments</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="number"
                                    placeholder="+254"
                                    aria-describedby="inputGroupPrepend"
                                    defaultValue={mpesaNumber}
                                    ref={mpesaNumberRef}
                                />
                            </InputGroup>
                        </Form.Group>
                        <br />
                        <Alert variant="primary">
                            <p>To see how much money you have made so far, navigate to <Link to="/earnings">Earnings Page</Link>. You can request withdrawal once your balance hits 1,000 {currencyName}.</p>
                        </Alert>
                    </div>

                    <br />
                    {/* privacy Section */}
                    <div className="card text-align-left">
                        <h4>Privacy</h4>
                        <br />
                        <Form.Check
                            type="switch"
                            id="make-account-private"
                            label="Make my account private"
                            checked={displayPrivacy}
                            onChange={() => setDisplayPrivacy(!displayPrivacy)}
                        />
                        <br />
                        <Alert variant="primary">
                            <p>The link to your profile or video call remains active, but your profile will not be visible in search results or listings. You can continue to make video calls and collect earnings. Use these privacy settings to limit your activity to video calls exclusively through Amanitouch or to take a break</p>
                        </Alert>
                    </div>
 

                    {showSignInbtn && (
                        <Button variant="primary" type="submit" className="btnLogin saveChgsBtn" onClick={saveChanges}>
                            Save Changes
                        </Button>
                    )}

                    {showProgress && (
                        <Button variant="dark" disabled className="btnLogin">
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            <span> Updating....</span>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AccountSettings;