import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDatabase, get, update, increment,ref, onValue, set} from "firebase/database"; // Import Realtime Database functions
import { getStorage, ref as myStorageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { faMapMarkerAlt, faPhone,faTrash, faCalendar, faEye, faCheckCircle, faComment, faVideo, faHome, faBriefcase, faPlayCircle 

} from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link } from 'react-router-dom';
import swal from 'sweetalert';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, getDocs,deleteDoc, where, onSnapshot,setDoc,addDoc, orderBy, Timestamp } from "@firebase/firestore"; 
import { doc, updateDoc } from "firebase/firestore";
import { app } from '../firebase.js';
import defaultCoverPhoto from '../Images/bg3.jpg';
import defaultProfilePhoto from '../Images/defprofile.png'
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar'; 
import { useLocation } from "react-router-dom";  
import Skeleton from 'react-loading-skeleton'; // Import skeleton component
import NavBar from '../Components/NavBar'
import NavBarAccount from '../Components/NavBarAccount.js';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';  
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import axios from 'axios';



const mapContainerStyle = {
width: '100%',
height: '60vh', 
};
 
const center = {
lat: -1.2921, // Default latitude (Nairobi)
lng: 36.8219, // Default longitude (Nairobi)
};


function ViewUser() {


    const location = useLocation();
    const userId = location.search.substring(1); // This removes the "?" and gets the userId directly
    const [loggedInUser, setloggedInUser] = useState("");
    const [viewPhone, setviewPhone] = useState("");
    const [theuserLocation, setuserLocation] = useState("")
    const [planType, setplanType] = useState('');
    const [isPlanActive, setIsPlanActive] = useState(false);
    const [userProfilePicture, setProfilePicture] = useState(defaultProfilePhoto);  // Default profile picture
    const [userCoverPicture, setCoverPicture] = useState(defaultCoverPhoto);  // Default cover picture
    const db = getFirestore(app);
    let navigate = useNavigate();
    const [aboutMe, setaboutMe] = useState("")
    const [products, setProducts] = useState([]);
    const [showAllProducts, setShowAllProducts] = useState(false); // State to toggle view more
    const [profileViews, setProfileViews] = useState(0); // To hold profile views
    const realtimeDb = getDatabase(app); // Initialize Realtime Database
    const [incallSchedule, setIncallSchedule] = useState([]);
    const [outcallSchedule, setOutcallSchedule] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [showIncallSchedule, setShowIncallSchedule] = useState(false);
    const [showOutcallSchedule, setShowOutcallSchedule] = useState(false);
    const [chargeForPhoneNumber, setchargeForPhoneNumber] = useState();
    const [enablePhoneCalls, setenablePhoneCalls] = useState();
    const [studentsList, setStudentList] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [mediaModalVisible, setMediaModalVisible] = useState(false);
    const [currentMedia, setCurrentMedia] = useState(null); 
    const [currencyCode, setcurrencyCode]= useState(''); 
    const [onlineStatus,setOnlineStatus ] = useState(''); 
    const [selectedAddress, setSelectedAddress] = useState(''); // Address fetched from Firestore
    const [geocodedCoordinates, setGeocodedCoordinates] = useState(null); // Store geocoded lat/lng
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyA297W_3urY4B_E0SbyfM_C_eqy6a6JGrY', // Replace with your Google Maps API Key
    });

    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [isLoading, setIsLoading] = useState(false); 
    const mpesaNumber = useRef();
    const [error, setError] = useState('');
    const fpPromise = FingerprintJS.load();


    
    const auth = getAuth(); 
    const [isLoggedIn, setIsLoggedIn] = useState(false); // To track user login status
    useEffect(() => {
        // Check user login status when the component is mounted
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is logged in
                setIsLoggedIn(true);
            } else {
                // User is logged out
                setIsLoggedIn(false);
            }
        });
    }, [auth]);

    const toggleIncallSchedule = () => {
        setShowIncallSchedule(!showIncallSchedule); 
    };

    const toggleOutcallSchedule = () => {
        setShowOutcallSchedule(!showOutcallSchedule); 
    };


    const toggleShowAllProducts = () => {
        setShowAllProducts(!showAllProducts);
    };

    useEffect(() => {
        const db = getDatabase(app);

        const userStatusRef = ref(db, `/status/${userId}`);
    
        // Listen for real-time updates to the user's status
        const unsubscribe = onValue(userStatusRef, (snapshot) => {
            const status = snapshot.val();
            if (status) {
            setOnlineStatus(status.state);
            }
        });
  
        // Cleanup the listener when the component unmounts
        return () => unsubscribe();
        
    }, [app]);
    
      // Get the products to display, either the first 3 or all, based on the toggle
    const displayedProducts = showAllProducts ? products : products.slice(0, 3);

    useEffect(() => {
            const uid = userId;
            const userQuery = query(collection(db, "users"), where("userId", "==", uid));
                
            // Fetch user data from Firestore
            getDocs(userQuery).then((querySnapshot) => {
                querySnapshot.forEach((docSnapshot) => {
                    const userData = docSnapshot.data();
                    setloggedInUser(userData.fullname);
                    setviewPhone(userData.phone);
                    setuserLocation(userData.userLocation); 
                    setaboutMe(userData.aboutMe)
                    setSelectedAddress(userData.userLocation)
                    setplanType(userData.planType);
                    setcurrencyCode(userData.currencyCode);
                    setchargeForPhoneNumber(userData.chargeForPhoneNumber);
                    setenablePhoneCalls(userData.displayPhoneNumber);


                    // Check if plan has expired
                    const currentDate = new Date();
                    const expiryDate = new Date(userData.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date

                    if (expiryDate > currentDate) {
                    setIsPlanActive(true);
                    } else {
                    setIsPlanActive(false);
                    }
                    
                    // Check for profile and cover photos in Firebase
                    if (userData.profilePic) {
                        setProfilePicture(userData.profilePic);
                    }
                    if (userData.coverPhoto) {
                        setCoverPicture(userData.coverPhoto);
                    }
                });
                setLoading(false); // Data is fetched, stop loading
            });

            // Fetch or initialize profile views from Realtime Database
            const viewsRef = ref(realtimeDb, `profileViews/${uid}`);

            get(viewsRef).then((snapshot) => {
                if (snapshot.exists()) {
                  // If data exists, get the current value
                  const data = snapshot.val();
                  setProfileViews(data.count || 0);
                } else {
                  // If data doesn't exist, initialize it to 0
                  set(viewsRef, { count: 0 }).then(() => {
                    setProfileViews(0);
                  });
                }
              }).then(() => {
                // Once the profile view is checked, increment only once
                update(viewsRef, {
                  count: increment(1)
                }).then(() => {
                  // After incrementing, fetch the new value to reflect it on the UI
                  get(viewsRef).then((snapshot) => {
                    if (snapshot.exists()) {
                      setProfileViews(snapshot.val().count); // Ensure "count" field exists
                    }
                  });
                });
              }); 
          
            const unsub = onSnapshot(doc(db, "userServices", uid), (doc) => {
                if (doc.exists()) {
                    // Extract the services from the Firestore document
                    const services = doc.data().selectedServices || [];
                    
                    // Map Firestore services to your component's expected structure
                    const formattedServices = services.map(service => ({
                        name: service.name,
                        price: `${service.minAmount}` // space between currency code and amount
                      })); 
              
                    // Update the state with the formatted services
                    setProducts(formattedServices); 
                }
            });

            const unsub3 = onSnapshot(doc(db, "incallOutcallSchedules", uid), (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
    
                    // Extract selected services, incalls, and outcalls
                    const services = data.selectedServices || [];
                    const incalls = data.availability?.incalls || {};
                    const outcalls = data.availability?.outcalls || {};
    
                    setSelectedServices(services);
    
                    // Map schedule for each day (Monday to Friday)
                    const formatSchedule = (schedule) => {
                        return Object.keys(schedule).map(day => ({
                            day,
                            from: schedule[day]?.from || 'N/A',
                            to: schedule[day]?.to || 'N/A'
                        }));
                    };
    
                    // Set the schedules for Incalls and Outcalls
                    setIncallSchedule(formatSchedule(incalls));
                    setOutcallSchedule(formatSchedule(outcalls));
                }
            });

            const fetchData = async () => {
                try {
                  const q = query(collection(db, 'uploads'), where("userId", "==", uid), orderBy('timeUploaded', 'desc'));
                  const querySnapshot = await getDocs(q);  
                  let studentsItem = [];
                  querySnapshot.forEach((doc) => {
                    studentsItem.push({ id: doc.id, ...doc.data() });
                  });
                  setStudentList(studentsItem);
                  setLoading(false);
                } catch (error) {
                  console.error('Error fetching data:', error);
                  setLoading(false);
                }
              };
              fetchData(); 

    }, [db, realtimeDb]);

    //view phone number



    function viewPhoneNumber(){

        if (enablePhoneCalls == false){
            swal("User has disabled phone calls; please contact them via video call instead");
        }else{
            if(chargeForPhoneNumber == true){
                swal({
                    title: "Phone number is private",
                    text: "Phone number is private. A token amount of KES. 100 is required to access it. Would you like to continue to view the phone number?",
                    icon: "info",
                    buttons: {
                      cancel: "Cancel",
                      confirm: "Continue"
                    }
                  }).then((willContinue) => {
                    if (willContinue) {
                        handleOpenModal();

                    } else {
                      // Cancel the action
                    }
                  });
                  
            }else{
                swal("Phone Number", viewPhone);
            } 
        }
    }

    const [loggedInUserIpAddress, setloggedInUserIpAddress] = useState('');
    const [loggedInvisitorId, setloggedInvisitorId] = useState();

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setloggedInUserIpAddress(data.ip))
            .catch(error => console.error('Error fetching IP address:', error));
    }, []); // The empty array ensures this effect runs only once, on component mount.

    // Get the visitor identifier when you need it.
    fpPromise 
    .then(fp => fp.get())
    .then(result => {
      // This is the visitor identifier:
      const visitorId = result.visitorId;
      setloggedInvisitorId(visitorId)
    }); 

    const formatPhoneNumber = (number) => {
        // Strip non-numeric characters
        let cleaned = number.replace(/[^0-9]/g, '');
    
        // Handle various cases
        if (cleaned.startsWith('0')) {
          cleaned = '254' + cleaned.slice(1); // Replace leading 0 with 254
        } else if (cleaned.startsWith('254')) {
          // No change needed
        } else if (cleaned.startsWith('7')) {
          cleaned = '254' + cleaned; // Add 254 if starts with 7
        } else if (cleaned.startsWith('+254')) {
          cleaned = cleaned.slice(1); // Remove the + sign
        } else if (cleaned.startsWith('1')) {
          cleaned = '254' + cleaned; // For landlines or similar cases starting with 1
        }
    
        // Ensure the number has 12 digits
        if (cleaned.length === 12 && cleaned.startsWith('254')) {
          return cleaned;
        } else {
          setError('Please enter a valid phone number in the format 254XXXXXXXXX');
          return null;
        }
      };

    function generateTransactionRef(length) {
        const characters = '0123456789';
        let result = '';
    
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
    
        return result;
    } 

    function handlePayment(){
        //
        const finalPhone = mpesaNumber.current.value;
        const formattedPhone = formatPhoneNumber(finalPhone);
  
        const finalAmount = 100;
    
        if (!formattedPhone) {
          toast.error(`Enter your phone number`);
          return; // Stop if phone number is invalid
        }
      
        setError(''); // Clear error if phone number is valid
        setIsLoading(true); // Show loading spinner
    
        sendStkPush(formattedPhone, finalAmount).finally(() => {
          setIsLoading(false); // Hide loading spinner after request completion
        });
    
      }

      const sendStkPush = async (phone, amount) => {
        try {
          const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/stkpush', {
            phone,
            amount
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
    
          const data = response.data;
          console.log('STK Push Response:', data);
    
          if (data.ResponseCode === '0') {
            // STK Push sent successfully
            swal({
              title: 'STK Push Sent',
              text: data.CustomerMessage,
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'I have paid',
                  value: 'confirm',
                  className: 'confirm-button'
                },
                cancel: 'I haven\'t paid'
              },
              content: {
                element: 'div',
                attributes: {
                  innerHTML: '<style>.confirm-button { position: relative; }</style>'
                }
              }
            }).then((value) => {
              if (value === 'confirm') {
                setIsLoading(true); // Show loading spinner on the "I have paid" button
    
                verifyPayment(data.CheckoutRequestID).then(() => {
                  setIsLoading(false); // Hide loading spinner after payment confirmation
                });
              } else {
                swal('Please complete the payment and try again.');
              }
            });
          } else {
            swal('Failed to send STK Push Request');
          }
        } catch (error) {
          console.error('Error sending STK Push request:', error);
          swal('Error sending STK Push Request. Please try again.');
        }
      };
    
      const verifyPayment = async (checkoutRequestId) => {
        try {
          const response = await axios.post(
            'https://us-central1-pepea-a9eaa.cloudfunctions.net/api/query',
            {
              queryCode: checkoutRequestId  // Ensure correct field name here
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
      
          const data = response.data;
          console.log('Payment Verification Response:', data);
      
          if (data.ResultCode === '0') {
    
            const checkReqID = data.CheckoutRequestID;
            const merchID = data.MerchantRequestID;
            const payMethod = "M-Pesa";
    
            recordPayments(checkReqID,merchID,payMethod);
    
    
          } else {
           // swal('Payment Not Verified', 'Your payment could not be verified.', 'error');
           toast.error(`Your payment could not be verified`);
          }
    
        } catch (error) {
          console.error('Error verifying payment:', error);
          toast.error(`Error verifying payment. Please try again`);
        }
    };

    function recordPayments(checkReqID,merchID,payMethod){

        toast.info(`Recording payment....`);
        const currentDate = Timestamp.now();
        const currency = "KES";
        const transRef = generateTransactionRef(5);

        const newDataRef = doc(collection(db, "phoneViews"));
        setDoc(newDataRef, {
            currency: currency,
            totalPayment:"100",
            status: 'Paid',
            timestamp: currentDate,
            documentId: newDataRef.id,
            viewerIpAddress:loggedInUserIpAddress,
            viewerVisitorId:loggedInvisitorId,
            transRef:transRef,
            userId:userId,
            checkReqID,
            merchID,
            payMethod
            
        }).then(()=>{
            swal("Phone Number", viewPhone);
        })

    }
 

    // Geocode the address to get latitude and longitude
    useEffect(() => {
        if (selectedAddress) {
        const geocodeAddress = async () => {
            const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(selectedAddress)}&key=AIzaSyA297W_3urY4B_E0SbyfM_C_eqy6a6JGrY`
            );
            const data = await response.json(); 
            if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setGeocodedCoordinates({ lat, lng });
            }
        };
        geocodeAddress();
        }
    }, [selectedAddress]);

    if (loadError) return 'Error loading maps';
    if (!isLoaded) return '';




  return (
    <div>
        <div>
            {/* If the user is logged in, show NavBarAccount, otherwise show NavBar */}
            {isLoggedIn ? <NavBarAccount /> : <NavBar />}
        </div>
        <section className='profPage'> 

            <div className='profTopCover clearfix'>
                <div className='profileHeader'>
                {loading ? (
                            <Skeleton height="100%" />
                        ) : (
                            <img src={userCoverPicture} alt="Cover" />
                        )}
                </div>

                <div className='viewProfPicCont'>
                        {loading ? (
                            <Skeleton circle={true} height="100%" width="100%" />
                        ) : (
                            <img src={userProfilePicture} alt="Profile" />
                        )}
                </div>

                <div className='profNameCont'>
                    <div>
                    {loading ? (
                            <Skeleton width={200} height={30} />
                        ) : (
                            <h3>{loggedInUser} 
                            {(isPlanActive && (planType === 'Standard Plan' || planType === 'Premium Plan')) && (
                                <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtn" />
                            )}  
                            </h3>
                        )}
                        {loading ? (
                            <Skeleton width={150} />
                        ) : (
                            <p><FontAwesomeIcon icon={faMapMarkerAlt} className='mapIcon' /> {theuserLocation}
                                <span
                                  className={
                                    onlineStatus === 'online'
                                      ? 'spanonline'
                                      : onlineStatus === 'on call'
                                      ? 'spanoncall'
                                      : 'spanoffline'
                                  }
                                >
                                  {onlineStatus === 'online'
                                    ? 'online'
                                    : onlineStatus === 'on call'
                                    ? 'on call'
                                    : 'offline'}
                                </span>
                            </p>
                        )}
                    </div>
                    <div className='userProfileContactCentre'> 
                        <div className='chatButton' onClick={viewPhoneNumber}>
                            <FontAwesomeIcon icon={faPhone} />
                        </div>
                        {/* <div className='chatButton' onClick={chatWithUser}>
                            <FontAwesomeIcon icon={faComment} />
                        </div> */}
                        <div className='chatButton'>
                            <Link to={"/call?" + userId} className='chatButtonVideo'>
                                <FontAwesomeIcon icon={faVideo} /> 
                            </Link>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className='profBottomCover'>

                {loading ? (
                <Skeleton height="200px" width="300px" />
                        ) : (
                <div className='profBottomOne'>

                    <p className='profileBio'>{aboutMe}</p>
                    <hr />
                    <p className='eyeIcon'><FontAwesomeIcon icon={faEye} /> {profileViews} profile visits</p>
                    <hr /> 

                    {/* If selected service is incalls */}
                    {selectedServices.includes('incalls') && (
                    <>
                    <p className='inoutCalls'>
                        <FontAwesomeIcon icon={faHome} className='callIcon' /> Incalls available
                    </p>
                    <p className='serviceTime' onClick={toggleIncallSchedule} style={{ cursor: 'pointer', color: 'royalblue' }}>
                        {showIncallSchedule ? "Hide Schedule" : "View Schedule"}
                    </p> 

                    {/* Only show schedule when clicked */}
                    {showIncallSchedule && (
                        <ul>
                            {incallSchedule.length > 0 ? (
                                incallSchedule.map((schedule, index) => (
                                    <li key={index} className="serviceTime">
                                        {schedule.day}: {schedule.from} - {schedule.to}
                                    </li>
                                ))
                            ) : (
                                <li className='serviceTime'>No incalls available</li>
                            )}
                        </ul>
                    )}
                </>
            )}

            {/* If selected service is outcalls */}
            {selectedServices.includes('outcalls') && (
                <>
                    <p className='inoutCalls2'>
                        <FontAwesomeIcon icon={faBriefcase} className='callIcon' /> Outcalls available
                    </p>
                    <p className='serviceTime' onClick={toggleOutcallSchedule} style={{ cursor: 'pointer', color: 'royalblue' }}>
                        {showOutcallSchedule ? "Hide Schedule" : "View Schedule"}
                    </p>

                    {/* Only show schedule when clicked */}
                    {showOutcallSchedule && (
                        <ul>
                            {outcallSchedule.length > 0 ? (
                                outcallSchedule.map((schedule, index) => (
                                    <li key={index} className="serviceTime">
                                        {schedule.day}: {schedule.from} - {schedule.to}
                                    </li>
                                ))
                            ) : (
                                <li className='serviceTime'>No outcalls available</li>
                            )}
                        </ul>
                    )}
                </>
            )}



                    <hr/>

                   

                    <ul>
                        {displayedProducts.map((product, index) => (
                        <li key={index} className="productItem">
                            <div className="productDetails">
                            <span className="productName">{product.name}</span>
                            <span className="productPrice"><span className='fromSpan'>from {currencyCode}.</span>{product.price}</span>
                            </div>
                        </li>
                        ))}
                    </ul>

                    {/* Conditionally show "View more services" */}
                    {products.length > 3 && (
                        <p onClick={toggleShowAllProducts} className="viewMoreServices">
                        {showAllProducts ? 'Show less services' : 'View more services'}
                        </p>
                    )}


                </div>
                )}
                <div className='profBottomTwo2'>


                    {studentsList.map((mystudent, index) => (
                    <div key={mystudent.id} className='servicePoviderListing2'>
                        
                        <div className='serviceProvLink' onClick={() => { 
                            setCurrentMedia(mystudent); 
                            setMediaModalVisible(true); 
                        }}>  
                            <div className='serviceProvImageCont2'>
                                {mystudent.fileType.includes('image') ? (
                                    <img src={mystudent.mediaUrl} alt="Uploaded Media" />
                                ) : mystudent.fileType.includes('video') ? (
                                    <div className="video-container">
                                        <video className="video-thumbnail">
                                            <source src={mystudent.mediaUrl} type={mystudent.fileType} />
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="play-button-overlay">
                                            <FontAwesomeIcon icon={faPlayCircle} className="play-icon" />
                                        </div>
                                    </div>
                                ) : (
                                    <p>Unsupported media type</p>
                                )}
                            </div>
                        </div>
                    </div>
                ))}




                       
                </div>
            </div>
        </section>

        {geocodedCoordinates ? (
       <GoogleMap
       mapContainerStyle={mapContainerStyle}
       zoom={16}
       center={geocodedCoordinates}
     >
       <Marker
         position={geocodedCoordinates}
         icon={{
           url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png', // Custom marker icon URL
           size: new window.google.maps.Size(50, 50), // Original size of the icon
           scaledSize: new window.google.maps.Size(50, 50), // Scaled size of the icon
           anchor: new window.google.maps.Point(25, 25), // Anchor the marker at its center
         }}
       />
     </GoogleMap>
      
      ) : (
        <p></p>
      )}


        {/* Media Modal */}
        <div className={`modal myModalMob fade ${mediaModalVisible ? 'show d-block' : 'd-none'}`} 
            tabIndex="-1" 
            role="dialog" 
            aria-hidden="true" 
            style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Media Viewer</h5>
                        <button 
                            type="button" 
                            className="close" 
                            onClick={() => setMediaModalVisible(false)}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {currentMedia && currentMedia.fileType.includes('video') ? (
                            <video controls className="w-100">
                                <source src={currentMedia.mediaUrl} type={currentMedia.fileType} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            currentMedia && <img src={currentMedia.mediaUrl} alt="Uploaded" className="w-100" />
                        )}
                    </div>
                </div>
            </div>
        </div>


        <Modal show={showModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>M-Pesa Payment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className="text-muted">
                    Please enter your M-Pesa phone number to complete the payment.
                  </p>
                  <div className="form-group mb-3">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="number"
                      id="phoneNumber"
                      className="form-control"
                      ref={mpesaNumber}
                      placeholder="Enter phone number starting with 254"
                    />
                  </div>
                  <p className="text-muted small">
                    You will receive a prompt on your phone to confirm the payment.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>

                <Button onClick={handlePayment} disabled={isLoading} variant="success">
                  {isLoading ? (
                    <>
                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                      <span> Processing...</span>
                    </>
                  ) : (
                    "Make Payment"
                  )}
                </Button>
              </Modal.Footer>
            </Modal> 

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Bounce}  
              />


    </div>
  )
}
 

export default ViewUser