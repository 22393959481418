import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';

function TermsOfService() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

    // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount
  
    
  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}

        <div className="terms-of-service">
      <h1>Terms of Service</h1>
      
      <section className="introduction">
        <h2>1. Introduction</h2>
        <p>
          Welcome to AmaniTouch. These Terms of Service govern your access to and use of our platform, services, and content. By accessing or using our platform, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our platform.
        </p>
      </section>
      
      <section className="account-eligibility">
        <h2>2. Account Eligibility</h2>
        <p>
          You must be at least 18 years old to create an account on AmaniTouch and use our services. By registering for an account, you represent and warrant that you meet this requirement. You are responsible for maintaining the confidentiality of your account login details and for all activities under your account.
        </p>
      </section>

      <section className="use-of-services">
        <h2>3. Use of Services</h2>
        <p>
          AmaniTouch provides a platform that connects clients with professional massage therapists. All services provided through AmaniTouch must comply with local laws and regulations. Users are prohibited from engaging in any unlawful or inappropriate activities on the platform.
        </p>
        <ul>
          <li>Use the platform for legitimate massage therapy services only.</li>
          <li>Respect the rights and privacy of others.</li>
          <li>Do not misuse the platform to solicit inappropriate or illegal services.</li>
        </ul>
      </section>

      <section className="payments-and-fees">
        <h2>4. Payments and Fees</h2>
        <p>
          Therapists agree to the pricing terms as outlined in their service agreement. Clients will be charged for services provided through the platform. AmaniTouch may charge processing fees or commissions for transactions made through the platform, which will be communicated transparently.
        </p>
      </section>

      <section className="booking-and-cancellation">
        <h2>5. Booking and Cancellation</h2>
        <p>
          Clients may book services directly through the platform. Therapists are responsible for managing their availability and honoring all bookings made through the platform. Clients may cancel bookings under the platform’s cancellation policy.
        </p>
        <ul>
          <li>Clients can cancel bookings in accordance with the therapist’s cancellation policy.</li>
          <li>Therapists must adhere to their availability and cancellation policies.</li>
        </ul>
      </section>

      <section className="termination-of-account">
        <h2>6. Termination of Account</h2>
        <p>
          AmaniTouch reserves the right to suspend or terminate your account at our discretion if you violate any of these Terms or engage in behavior that is harmful to the platform or its users. We may also suspend access to your account if required by law or under investigation for misconduct.
        </p>
      </section>

      <section className="prohibited-activities">
        <h2>7. Prohibited Activities</h2>
        <p>
          Users of AmaniTouch are prohibited from engaging in the following activities:
        </p>
        <ul>
          <li>Providing or soliciting illegal services.</li>
          <li>Engaging in any form of harassment, abuse, or discrimination.</li>
          <li>Misusing the platform to scam or deceive other users.</li>
          <li>Sharing sensitive or confidential information without permission.</li>
          <li>Engaging in activities that disrupt or harm the platform’s infrastructure.</li>
          <li>Uploading or sharing any content that is not appropriate for all ages.</li>
        </ul>
      </section>

      <section className="user-content">
        <h2>8. User Content</h2>
        <p>
          Users may post reviews, feedback, and other content on the platform. By posting content, you grant AmaniTouch a non-exclusive, royalty-free license to use, display, and distribute your content. You are responsible for ensuring that the content you post is accurate, lawful, and does not infringe the rights of others.
        </p>
      </section>

      <section className="privacy-policny">
        <h2>9. Privacy Policy</h2>
        <p>
          Your use of AmaniTouch is subject to our <a href="/privacy-policy">Privacy Policy</a>. We collect, use, and share personal data as described in our Privacy Policy. By using the platform, you consent to the data practices outlined in the Privacy Policy.
        </p>
      </section>

      <section className="limitation-of-liability">
        <h2>10. Limitation of Liability</h2>
        <p>
          AmaniTouch is not responsible for any damages arising from the use or inability to use the platform, or from the performance of the therapists. We do not guarantee the quality of services provided by therapists and are not liable for any harm or injury caused by such services.
        </p>
      </section>

      <section className="modifications">
        <h2>11. Modifications to Terms</h2>
        <p>
          AmaniTouch reserves the right to modify these Terms at any time. Any changes will be posted on this page, and your continued use of the platform after the changes take effect constitutes your acceptance of the revised Terms.
        </p>
      </section>

      <section className="dispute-resolution">
        <h2>12. Dispute Resolution</h2>
        <p>
          Any disputes arising from or related to these Terms or the platform will be resolved through binding arbitration under the laws of the jurisdiction in which AmaniTouch operates. You agree to waive your right to participate in any class-action lawsuits against AmaniTouch.
        </p>
      </section>

    </div>

        <Footer />
    </div>
  )
}

export default TermsOfService