import React from 'react'
import logo from '../Images/logo.png'
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars} from '@fortawesome/free-solid-svg-icons'
import profpicHolder from '../Images/profimage.svg'
import Dropdown from 'react-bootstrap/Dropdown';


function NavBar() {
  return (
    <div className='navbar'>
        <Link to='/'>
            <img src={logo} className='logo' alt="" />
        </Link>

        <div className='navbarTwo'>
            
            <Link to='/signup' className='signuptext'>
                Become a Therapist
            </Link>

            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown-toggle">
                    <div className='accountCont'>
                        <FontAwesomeIcon icon={faBars} className="barsIcon"/>
                        <div className='profileCont'>
                            <img src={profpicHolder} alt="Profile" />
                        </div>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="/signup">Sign Up</Dropdown.Item>
                    <Dropdown.Item href="/login">Login</Dropdown.Item>
                    <Dropdown.Item href="/reset-password">Reset Password</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        </div>
    </div>
  )
}

export default NavBar