import React, { useRef, useState, useEffect } from 'react';
import './Auth.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';
import logo from '../Images/logo.png';
import InputGroup from 'react-bootstrap/InputGroup';  
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut
} from 'firebase/auth';
import { collection, doc, setDoc } from '@firebase/firestore';
import { useLocation } from '../LocationContext'; 
import FingerprintJS from '@fingerprintjs/fingerprintjs';


function Register() {
  let navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const genderRef = useRef();
  const [showProgress, setShowProgress] = useState(false);
  const [showSignInbtn, setShowSignInbtn] = useState(true);
  const { location, currency } = useLocation();
  const [loggedInUserIpAddress, setloggedInUserIpAddress] = useState('');
  const fpPromise = FingerprintJS.load();
  const [loggedInvisitorId, setloggedInvisitorId] = useState();

  useEffect(() => {
      fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => setloggedInUserIpAddress(data.ip))
          .catch(error => console.error('Error fetching IP address:', error));
  }, []); //

  // Get the visitor identifier when you need it.
  fpPromise
  .then(fp => fp.get())
  .then(result => {
    // This is the visitor identifier:
    const visitorId = result.visitorId;
    setloggedInvisitorId(visitorId)
  });
  

  async function loginUser() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const username = nameRef.current.value;
    const phone = phoneRef.current.value;
    const userGender = genderRef.current.value;
    const timestamp = new Date();
    const userCountry = location.country;
    const usercountryName = currency.countryName;
    const userRegion = location.region;
    const useCity = location.city;
    const compLoc = useCity +", " +usercountryName; 
    const aboutInfo = 'Hi, I’m ' + username + '!' +' 🌸 I’m here to help you relax, unwind, and feel amazing through soothing massage therapy. Whether you need stress relief or muscle recovery, I’ve got you covered! 💆‍♀️💆‍♂️ Let’s make your day a little brighter! ✨'
    

    setShowProgress(true);
    setShowSignInbtn(false);

    const auth = getAuth();
    const db = getFirestore(app);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Send email verification
      await sendEmailVerification(auth.currentUser);

      // Update user data in the database
      const userID = userCredential.user.uid;
      const newUserRef = doc(db, 'users', userID);
      await setDoc(newUserRef, {
        email: email,
        phone: phone,
        signupdate: timestamp,
        userId: userID,
        usertype: 'therapist',
        registeredVia: 'Web',
        isuserVerified: 'no',
        fullname: username,
        userLocation: compLoc,
        gender:userGender,
        age:18,
        planType:'Free Plan',
        expiryTime:timestamp,
        minPrice:10,
        lat: parseFloat(location.userLat), // Keep as floating-point
        lng: parseFloat(location.userLng), // Keep as floating-point
        country:userCountry,
        countryName:currency.countryName,
        region:userRegion,
        city:useCity,
        currencyName:currency.name,
        currencyCode:currency.code,
        currencySymbol:currency.symbol,
        aboutMe: aboutInfo,
        userIpAddress:loggedInUserIpAddress,
        visitorId:loggedInvisitorId
      });

        // Example usage:
        const message = "Hi "+username+ "! Welcome to AmaniTouch! We're excited to have you on board. Start exploring and connect with thousands of clients near you and around the world. Let's get started!";
        const phoneNumber = phone; 
        
        sendSms(message, phoneNumber);

        // Log out the user
        await signOut(auth);

     

      // Redirect to the dashboard after successful signup
      sendWelcomeEmail(email, username, userID);
     
      

    } catch (error) {
      setShowProgress(false);
      setShowSignInbtn(true);

      const errorCode = error.code;
      const errorMessage = error.message;
      swal('Sign up Error!', '' + errorMessage + '', 'error');
    }
  }
  


  async function sendSms(message, phoneNumber) {
    const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/amanisms/sendsms';
    const requestBody = {
      message,
      phoneNumber
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        // Handle the response as needed
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }
  
  function sendWelcomeEmail(sendEmail, username, userID) {

    const profileList = "https://amanitouch.app/view-user?" +userID;
    const videoLink = "https://amanitouch.app/call?"+userID;

    const sendSubject = "WELCOME TO AMANITOUCH!";
  
    // HTML Email content
    const htmlEmailContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Welcome to AmaniTouch</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f7f7f7;
                color: #333;
                margin: 0;
                padding: 0;
            }
            .container {
                max-width: 600px;
                margin: 20px auto;
                background-color: #ffffff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            h1 {
                color: #2c3e50;
            }
            p {
                font-size: 16px;
                line-height: 1.6;
            }
            .button {
                display: inline-block;
                background-color: #1abc9c;
                color: #ffffff;
                padding: 10px 20px;
                text-decoration: none;
                border-radius: 5px;
                margin-top: 20px;
            }
            .button:hover {
                background-color: #16a085;
            }
            .footer {
                margin-top: 30px;
                font-size: 14px;
                color: #999;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <h1>Welcome to AmaniTouch!</h1>
            <p>Dear ${username},</p>
  
            <p><strong>Welcome to AmaniTouch! 🎉</strong> We’re thrilled to have you join our community of skilled massage therapists, and we can’t wait to help you grow your business.</p>
  
            <p>At AmaniTouch, you’ll connect with clients from near and far, showcase your talents, and unlock new earning opportunities. Whether you’re just starting or a seasoned therapist, we’re here to help you take your business to the next level!</p>
  
            <h2>Here’s what you can do right away:</h2>
  
            <ul>
                <li><strong>Set Up Your Profile:</strong> Make your profile shine at no cost! Upload your best images and videos, list your services, and display your availability—all for free. A complete profile helps you stand out and attract more clients, helping you grow your business effortlessly.</li>
                <li><strong>Connect With Clients:</strong> Once your profile is complete, clients will be able to view it and contact you directly. Professional photos and detailed information about your services are essential to making a strong first impression and attracting more bookings. This is the public link to your profile: <a href="${profileList}" target="_blank">${profileList}</a>. Share it within your circles to reach more clients and grow your business!</li>
                <li><strong>Offer Private Calls:</strong> Turn your expertise into a steady income stream by offering private video consultations. With earnings starting from KES 100 per minute, you can make thousands of shillings daily, depending on your availability and demand. This is your public video call link: <a href="${videoLink}" target="_blank">${videoLink}</a>. Share it with potential clients to start earning today!</li>
                <li><strong>View Earnings:</strong> Keep track of your daily income effortlessly! With our platform, you can view your earnings from video calls and phone number views (if enabled in your settings) right on the earnings page. Each time someone pays a small token to view your phone number, it adds to your income. Stay informed and motivated by checking your earnings regularly. Here’s the link to your earnings page: <a href="https://amanitouch.app/earnings" target="_blank">https://amanitouch.app/earnings</a>.</li>
            </ul>
  
            <p><strong>Need Help?</strong><br>We’re here for you every step of the way. If you have any questions or need assistance, reach out to us at <a href="mailto:info@amanitouch.app">info@amanitouch.app</a> or call us at +1 (413) 273-7761.</p>
  
            <a href="https://amanitouch.app/login" class="button">Let’s get started!</a>
  
            <div class="footer">
                <p>Thank you for choosing AmaniTouch. We believe that with your skills and our platform, great things are ahead! 🚀</p>
                <p>Best Regards,<br>The AmaniTouch Team</p>
            </div>
        </div>
    </body>
    </html>
    `;
  
    // Email data
    const emailData = {
      email: sendEmail,
      message: htmlEmailContent, // Sending the HTML content
      subject: sendSubject
    };
  
    fetch('https://us-central1-pepea-a9eaa.cloudfunctions.net/amanicommapi/send-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(emailData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Email response:', data);
        setShowProgress(false);
        setShowSignInbtn(true);
        swal({
          title: "Registration Successful!",
          text: "Please check your email for verification link",
          icon: "success",
          button: "Okay",
        }).then((value) => {
          navigate('/login');
        });

      })
      .catch(error => {
        console.error('Error sending email:', error);
        setShowProgress(false);
        setShowSignInbtn(true);
        swal({
          title: "Registration Successful!",
          text: "Please check your email for verification link",
          icon: "success",
          button: "Okay",
        }).then((value) => {
          navigate('/login');
        });

      });
  }
  


  
  return (
    <div className='mainAuth'>
      <div className='AuthRight'>

      {/* <Link to='/'> 
        <img src={logo} alt='' className='logoAuth' />
      </Link> */}

      
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Full Name</Form.Label>
          <Form.Control type='text' placeholder='Your full name' ref={nameRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGender'>
          <Form.Label>Gender</Form.Label>
          <Form.Select aria-label='Select Gender' ref={genderRef}>
            <option value='Female'>Female</option>
            <option value='Male'>Male</option>
            <option value='Non Binary'>Non-binary</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Valid Email address</Form.Label>
          <Form.Control type='email' placeholder='Enter a verifiable email address' ref={emailRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type='number' placeholder='Phone number' ref={phoneRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' placeholder='Password' ref={passwordRef} />
        </Form.Group>

        <Link to='/terms-of-service' className='signuptext2'>By signing up you agree to our <b>Terms of Service</b></Link>
        {showSignInbtn && (
          <Button variant='primary' type='submit' className='btnLogin' onClick={loginUser}>
            Sign up
          </Button>
        )}

        {showProgress && (
          <Button variant='dark' disabled className='btnLogin'>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            <span> Signing up...</span>
          </Button>
        )}

        <div className='signuptextCont'>
            <Link to='/login' className='signuptext'>Login to your Account </Link> <h6>|</h6>
            <Link to='/reset-password' className='signuptext'> Reset Password</Link>
        </div>
 
      </div>

      <div className='AuthLeft'>
        <div className='authleftCont'>
          <video
            autoPlay
            muted
            loop
            src='https://res.cloudinary.com/ubunifu/video/upload/v1727119071/6186707-uhd_3840_2160_25fps_g2j9sg.mp4'
          ></video>
          <div className='authLeftOpacity'></div>
        </div>
      </div>
    </div>
  );
}

export default Register;