import React, { useState, useEffect } from 'react'; 
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';
import swal from 'sweetalert';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, getDocs, where } from "@firebase/firestore"; 
import { app } from '../firebase.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'; 
import { useLocation } from '../LocationContext'; // Import the custom hook 

function ViewMore() {
    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app); 
    const [loading, setLoading] = useState(false); // Loading state  
    const [showAccountSection, setShowAccountSection] = useState(true); // Control account section visibility
    const { location, currency } = useLocation();
    const [countryNames, setCountryNames] = useState('Kenya');

    useEffect(() => {
        if (location && currency) { // Ensure both location and currency are ready
            setCountryNames(currency.countryName);
        }
    }, [location, currency]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                const q = query(
                    collection(db, 'users'),
                    where("isuserVerified", "==", "yes"), // Fetch only verified users
                    where("countryName", "==", countryNames) // Filter by the current country
                );
                
                const querySnapshot = await getDocs(q);
                let activeUsers = [];
                let inactiveUsers = [];

                querySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    const expiryDate = new Date(userData.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date
                    const isPlanActive = expiryDate > new Date(); // Check if the plan is still active
                    const validPlanTypes = ['Standard Plan', 'Premium Plan'];
                    const hasValidPlanType = validPlanTypes.includes(userData.planType);

                    if (isPlanActive && hasValidPlanType) {
                        activeUsers.push({ id: doc.id, ...userData }); // Push to active users list
                    } else {
                        inactiveUsers.push({ id: doc.id, ...userData }); // Push to inactive users list
                    }
                });

                setStudentList([...activeUsers, ...inactiveUsers]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false); // Stop loading
        };
      
        if (countryNames) {
            fetchData(); // Fetch data only when countryNames is available
        }
    }, [db, countryNames]); // Re-run the effect when countryNames changes

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });
    }, [auth]);

    // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount


    return (
        <div className='searchResults'>
            {/* NavBar based on login status */}
            {isLoggedIn ? <NavBarAccount /> : <NavBar />}

            <br/>
            <h3>Showing results from</h3>
            <h4 className='searchResults'>{countryNames}</h4>

            {loading ? (
                <div className="loading-spinner">
                    {/* You can use a CSS spinner or an actual component */}
                    <p>Loading...</p>
                </div>
            ) : (
                <>
                    {studentsList.length > 0 ? (
                        showAccountSection && (
                            <section className='accountSection'>
                                {studentsList.map((mystudent) => {
                                    const expiryDate = new Date(mystudent.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date
                                    const isStudentPlanActive = expiryDate > new Date(); // Check if plan is still active
                                    const studentPlanType = mystudent.planType;

                                    return (
                                        <div key={mystudent.userId} className='servicePoviderListing'>
                                            <Link to={"/view-user?" + mystudent.userId} className='serviceProvLink'>
                                                <div className='serviceProvImageCont'>
                                                    <img src={mystudent.profilePic} alt="" />
                                                </div>
                                                <h6>
                                                    {mystudent.fullname}
                                                    {(isStudentPlanActive && (studentPlanType === 'Standard Plan' || studentPlanType === 'Premium Plan')) && (
                                                        <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtn" />
                                                    )}
                                                </h6>
                                                <p>{mystudent.userLocation}</p>
                                                <p>From <span>{mystudent.currencyCode}. {Number(mystudent.minPrice).toLocaleString()}</span></p>
                                            </Link>
                                        </div>
                                    );
                                })} 
                            </section>
                        )
                    ) : (
                        <div className="no-results">
                            <h4>No users found in your country</h4>
                            <p>Sign up as a therapist today!</p>
                            <Link to="/signup" className="sign-up-btn">Sign Up</Link>
                        </div>
                    )}
                </>
            )}

            <Footer />
        </div>
    );
}

export default ViewMore;