import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';

function SafetyPage() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

    // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);  // Runs only on mount
  

  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}
        <div className="safety-page">
      <h1>Safety & Security at AmaniTouch</h1>

      <section className="safety-section">
        <h2>Our Commitment to Your Safety</h2>
        <p>
          At AmaniTouch, your safety and security are our top priorities. We are dedicated to creating a trustworthy platform that ensures a safe experience for both clients and massage therapists. Our policies and tools are designed to provide peace of mind, so you can focus on enjoying and providing the best massage therapy experience possible.
        </p>
      </section>

      <section className="safety-section">
        <h2>Verified Profiles</h2>
        <p>
          All massage therapists on AmaniTouch are required to provide documentation of their qualifications and certifications. We manually verify these profiles to ensure that only licensed and certified professionals are listed on our platform. This process helps us build trust and ensures that you’re in safe, capable hands.
        </p>
      </section>

      <section className="safety-section">
        <h2>Client Reviews and Ratings</h2>
        <p>
          After every session, clients can leave reviews and ratings for their therapist. This feedback helps maintain high standards of professionalism and allows other clients to make informed decisions when booking a massage. We closely monitor reviews to ensure authenticity and integrity.
        </p>
      </section>

      <section className="safety-section">
        <h2>Secure Payments</h2>
        <p>
          All payments made through AmaniTouch are processed securely via our payment gateway. We use industry-standard encryption to protect your financial data. You can rest assured that your transactions are safe, and your personal information remains confidential.
        </p>
      </section>

      <section className="safety-section">
        <h2>Privacy Protection</h2>
        <p>
          AmaniTouch is committed to protecting your privacy. We do not share your personal information with third parties without your consent. For more information, please review our <a href="/privacy-policy">Privacy Policy</a>.
        </p>
      </section>

      <section className="safety-section">
        <h2>24/7 Support</h2>
        <p>
          Our support team is available 24/7 to assist with any concerns or issues. If you ever feel unsafe or encounter a problem, please reach out to us immediately. We take all reports seriously and will take appropriate action to address any safety or security concerns.
        </p>
      </section>

      <section className="safety-section">
        <h2>Tips for Clients</h2>
        <ul>
          <li>Always check the therapist’s profile and reviews before booking.</li>
          <li>Communicate your needs and expectations clearly before the session begins.</li>
          <li>If you feel uncomfortable at any time, you can cancel the session.</li>
          <li>Ensure that all communication and payment are conducted through the AmaniTouch platform for your protection.</li>
        </ul>
      </section>

      <section className="safety-section">
        <h2>Tips for Therapists</h2>
        <ul>
          <li>Verify all client details before confirming an appointment.</li>
          <li>Choose a safe and comfortable environment for your sessions.</li>
          <li>If a client makes you feel uncomfortable or unsafe, report them immediately.</li>
          <li>Maintain professionalism and clear boundaries throughout the session.</li>
        </ul>
      </section>

      <section className="safety-section">
        <h2>Reporting Issues</h2>
        <p>
          If you encounter any suspicious behavior or have concerns about your safety, please report it to us immediately. We will investigate the issue and take the necessary actions to ensure the safety of our community. You can reach our support team at <a href="mailto:info@amanitouch.app">info@amanitouch.app</a>.
        </p>
      </section>
    </div>

        <Footer />
    </div>
  )
} 

export default SafetyPage