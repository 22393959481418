import React, { useState, useRef, useEffect } from 'react';
import './Auth.css';
import NavBar from '../Components/NavBar'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, getDocs, where } from "@firebase/firestore"; 
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { app } from '../firebase.js';
import defaultCoverPhoto from '../Images/bg3.jpg';
import defaultProfilePhoto from '../Images/defprofile.png'
import swal from 'sweetalert';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import toastr from 'toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { faMapMarkerAlt, faPhone,faTrash, faCalendar, faEye, faCheckCircle, faComment, faVideo, faHome, faBriefcase, faPlayCircle 

} from '@fortawesome/free-solid-svg-icons'
import NavBarAccount from '../Components/NavBarAccount.js';
import InputGroup from 'react-bootstrap/InputGroup';
import { useLocation } from '../LocationContext'; 


function EditProfile() {

    const { location, currency } = useLocation();
    const [fromLocation, setFromLocation] = useState({ lat: 0, lng: 0 });
    const [fromAddress, setFromAddress] = useState('');  // Default location 
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
    const [loggedInUser, setloggedInUser] = useState("");
    const [viewPhone, setviewPhone] = useState("");

    useEffect(() => {
        if (location && currency) { // Ensure both location and currency are ready
            setFromLocation({
                lat: parseFloat(location.userLat),  // Convert to float if necessary
                lng: parseFloat(location.userLng)   // Convert to float if necessary
            });

            // Construct the address from the city and country
            const city = location.city || 'Nairobi'; // Fallback to default
            const country = currency.countryName || 'Kenya'; // Fallback to default
            setFromAddress(`${city}, ${country}`);
        }
    }, [location, currency]);


    const fileInput = useRef();
    const coverInput = useRef();

    const [userProfilePicture, setProfilePicture] = useState(defaultProfilePhoto);  // Default profile picture
    const [userCoverPicture, setCoverPicture] = useState(defaultCoverPhoto);  // Default cover picture
    
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertTwo, setShowAlertTwo] = useState(false);
    const [UploadBtn, setUploadBtn] = useState("Upload");
    const [uploadProfileStatus, setuploadProfileStatus] = useState("1");
    const [uploadStatStatus, setuploadStatStatus] = useState("Uploading...0%");
    const [theuserLocation, setuserLocation] = useState("Kileleshwa, Nairobi, Kenya")

    const [planType, setplanType] = useState('');
    const [expiryTime, setExpiryTime] = useState(null);
    const [isPlanActive, setIsPlanActive] = useState(false);

    const [aboutMe, setaboutMe] = useState("")

    const [currencyCode, setCurrencyCode] = useState('');
    const [currencyName, setcurrencyName] = useState('');
    const [minPrice, setminPrice]  = useState(''); 


    //lets get the references here
    const phoneRef = useRef();
    const userNameRef = useRef();
    const aboutMeRef = useRef();
    const minPriceRef = useRef();

    const [theUser, setTheUser] = useState();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setTheUser(user.uid);
            } else {
                setTheUser(null); // Handle the case where the user is logged out
            }
        });
    
        return () => unsubscribe(); // Cleanup on unmount
    }, []); // Empty dependency array to run only once (when the component mounts)

    //instantiate storage
    const storage = getStorage();

    const db = getFirestore(app);
    let navigate = useNavigate();
 

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            const userQuery = query(collection(db, "users"), where("userId", "==", uid));
                
                // Fetch user data from Firestore
                getDocs(userQuery).then((querySnapshot) => {
                    querySnapshot.forEach((docSnapshot) => {
                        const userData = docSnapshot.data();
                        setloggedInUser(userData.fullname);
                        setviewPhone(userData.phone);
                        setuserLocation(userData.userLocation); 
                        setaboutMe(userData.aboutMe)
                        setCurrencyCode(userData.currencyCode)
                        setcurrencyName(userData.currencyName) 
                        setminPrice(userData.minPrice) 
                        setplanType(userData.planType);
                       // setFromAddress(userData.userLocation); 

                                            // Check if plan has expired
                        const currentDate = new Date();
                        const expiryDate = new Date(userData.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date

                        if (expiryDate > currentDate) {
                        setIsPlanActive(true);
                        } else {
                        setIsPlanActive(false);
                        }
                        
                        // Check for profile and cover photos in Firebase
                        if (userData.profilePic) {
                            setProfilePicture(userData.profilePic);
                        }
                        if (userData.coverPhoto) {
                            setCoverPicture(userData.coverPhoto);
                        }
                    });
                });

            //update profile picture 
            window.saveProfilePhoto = function(){
                const profilePhoto = fileInput.current.files[0];   

                const storageRef = ref(storage, 'images/' + Math.random() + profilePhoto.name);
                const uploadTask = uploadBytesResumable(storageRef, profilePhoto);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        setShowAlert(true);              
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const roundedOffProgress = Math.round(progress);

                        setuploadProfileStatus(roundedOffProgress);
                        setuploadStatStatus('Upload is ' + roundedOffProgress + '% done');
                        setUploadBtn("Uploading.."+roundedOffProgress +'%')
                        
                    }, 
                (error) => {
                    // Handle unsuccessful uploads
                }, 
                () => {
                    // Handle successful uploads on complete
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                        updateDoc(doc(db, "users", user.uid), {
                            profilePic: downloadURL

                        }).then(()=>{
                            swal("Success", "Profile picture updated successfully!", "success").then((value) => {
                                window.location.reload(false);
                                });
                            //
                        })  
                    });
                }
                );
            }
            //end profile picture

            //update Cover photo
            window.saveCoverPhoto = function(){

                const coverPhoto = coverInput.current.files[0];   

                const storageRef = ref(storage, 'images/' + Math.random() + coverPhoto.name);
                const uploadTask = uploadBytesResumable(storageRef, coverPhoto);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        setShowAlertTwo(true);              
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const roundedOffProgress = Math.round(progress);

                        setuploadProfileStatus(roundedOffProgress);
                        setuploadStatStatus('Upload is ' + roundedOffProgress + '% done');
                        setUploadBtn("Uploading.."+roundedOffProgress +'%')
                        
                    }, 
                (error) => {
                    // Handle unsuccessful uploads
                }, 
                () => {
                    // Handle successful uploads on complete
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                        updateDoc(doc(db, "users", user.uid), {
                            coverPhoto: downloadURL

                        }).then(()=>{
                            swal("Success", "Cover photo updated successfully!", "success").then((value) => {
                                window.location.reload(false);
                                });
                            //
                        })  
                    });
                }
                );

            }

            window.goToNext = function(){

                const userQuery = query(collection(db, "users"), where("userId", "==", uid));
                
                // Fetch user data from Firestore
                getDocs(userQuery).then((querySnapshot) => {
                    querySnapshot.forEach((docSnapshot) => {
                        const userData = docSnapshot.data();
                        
                        // Check for profile and cover photos in Firebase
                        if (userData.profilePic && userData.coverPhoto && userData.userLocation && userData.fullname) {
                            navigate('/services-set-up');
                        }else{
                            swal("Error", "Please update all info before proceeding!", "info")
                        }

                    });
                });

            }

            ////
        } else {
            navigate("/");  
        }
    });


    const [lat, setLat] = useState(0); // New state for latitude
    const [lng, setLng] = useState(0); // New state for longitude

    const handleFromSelect = async (address) => {
        setFromAddress(address);  // Update displayed location with selected address
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            setFromLocation(latLng);
            setLat(latLng.lat); // Update lat state
            setLng(latLng.lng); // Update lng state
        } catch (error) {
            console.error('Error', error);
        }
    };

    // UseEffect to handle changes in fromLocation
    useEffect(() => {
        console.log("Updated Latitude: ", fromLocation.lat);
        console.log("Updated Longitude: ", fromLocation.lng);
        
    }, [fromLocation]);




    function updateProfilePhoto(){
        const profilePhoto = fileInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setProfilePicture(url)
    }

    //update profile picture on select
    function updateCoverPhoto(){
        const profilePhoto = coverInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setCoverPicture(url)
    }


    function viewPhoneNumber(){
        swal("Phone Number", viewPhone);
    }

    function chatWithUser(){
        swal("Info", "User doesnt support chat", "info", {
            button: "Okay",
          });
    }

    function videoCallWithUser(){
        swal("Info", "User doesnt support video call", "info", {
            button: "Okay",
          });
    }


    function getVerified(){
        navigate("/get-verified");  
    } 

                
    function saveChanges(){

        const theFullName =  userNameRef.current.value;
        const thePhoneNumber = phoneRef.current.value;
        const aboutMeInfo = aboutMeRef.current.value;
        const theminPrice = minPriceRef.current.value;

        setShowProgress(true);
        setShowSignInbtn(false);

        updateDoc(doc(db, "users", theUser), {

            fullname: theFullName,
            phone:thePhoneNumber,
            userLocation: fromAddress,
            aboutMe:aboutMeInfo,
            lat:fromLocation.lat,
            lng:fromLocation.lng,
            minPrice:parseInt(theminPrice)

        }).then(()=>{
            setShowProgress(false);
            setShowSignInbtn(true);
            swal("Success", "Info updated Successfully!", "success").then((value) => {
                window.location.reload(false);
                });
            //
        })  

    }


    return (
        <div>
                <NavBarAccount/>   
            <div className='profPage'>
                    <div className='profTopCover clearfix'>
                        <div className='profileHeader'>
                            <img src={userCoverPicture} alt="" />
                        </div>
                        <div className='viewProfPicCont'>
                            <img src={userProfilePicture} alt="" />
                        </div>
                        <div className='profNameCont'>
                            <div>
                            <h3>
                        {loggedInUser}
                        {isPlanActive && (planType === 'Standard Plan' || planType === 'Premium Plan') ? (
                            <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtn" />
                        ) : (
                            <span className='getverifiedSpan getverifiedSpan33' onClick={getVerified}>Get Verified</span>
                        )}
                        </h3> 
                                <p><FontAwesomeIcon icon={faMapMarkerAlt} className='mapIcon' /> {theuserLocation}</p>
                            </div>

                            
                        </div>
                    </div>

                    <div className='profBottomCover2 bottomPageEdit'>

                        <Form.Group className='mb-3' controlId='formBasicAboutMe'>
                            <Form.Label>About Me</Form.Label>
                            <Form.Control 
                                as='textarea' 
                                placeholder='A brief description about yourself' 
                                defaultValue={aboutMe} 
                                ref={aboutMeRef} 
                            /> 
                        </Form.Group>


                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Name</Form.Label> 
                            <Form.Control type='text' placeholder='Enter name' defaultValue={loggedInUser} ref={userNameRef}/> 
                        </Form.Group>

                        <div className='alert alert-primary'>
                            <p className='alertPr'>Select the location that best represents where you're based. Your profile will be shown to users searching nearby this area.</p>
                        </div> 

                        <Form.Group className="mb-4" controlId="fromLocation">
                            <Form.Label>Search for a location</Form.Label>
                            <PlacesAutocomplete value={fromAddress} onChange={setFromAddress} onSelect={handleFromSelect}>
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <Form.Control {...getInputProps({ placeholder: 'Select a location' })} />
                                        <div className='pickupDivsc'>
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => (
                                                <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                                                    {suggestion.description}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete> 
                        </Form.Group> 

                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Phone Number (Client Contact Number)</Form.Label>
                            <Form.Control type='text' placeholder='Enter Phone' defaultValue={viewPhone}  ref={phoneRef}/>
                        </Form.Group>

                        <Form.Group md="4" controlId="validationCustomUsername">
                        <Form.Label>Minimum Amount for my services in {currencyName}</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">{currencyCode}</InputGroup.Text>
                            <Form.Control
                            type="text"
                            placeholder="Username"
                            aria-describedby="inputGroupPrepend"
                            defaultValue={minPrice}  
                            ref={minPriceRef}
                            />
                            <Form.Control.Feedback type="invalid">
                            Please choose a username.
                            </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>

                        {showSignInbtn && (
                            <Button variant='primary' type='submit' className='btnLogin saveChgsBtn' onClick={saveChanges}>
                                Save Changes
                            </Button>
                        )} 

                        {showProgress && (
                            <Button variant='dark' disabled className='btnLogin'>
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                <span> Updating....</span>
                            </Button>
                        )}
                    </div>

                    <hr />

                    <div className='EditProfileBody bottomPageEdit'> 
                    <h3>Update Profile & Cover Photos</h3>  
                    <br />

                    <p>Profile Picture</p>
                    <div className='d-flex'>         
                        <input type="file" ref={fileInput} className="form-control" onChange={updateProfilePhoto}/>             
                        <button onClick={window.saveProfilePhoto}>{UploadBtn}</button>
                    </div>
                    <br />

                    {showAlert && 
                        <Alert>
                            {uploadStatStatus}
                            <ProgressBar striped variant="info" now={uploadProfileStatus} />
                        </Alert>
                    }

                    <p>Cover Photo</p>
                    <div className='d-flex'>         
                        <input type="file" ref={coverInput} className="form-control" onChange={updateCoverPhoto}/>             
                        <button onClick={window.saveCoverPhoto}>{UploadBtn}</button>
                    </div>
                    <br />

                    {showAlertTwo && 
                        <Alert>
                            {uploadStatStatus}
                            <ProgressBar striped variant="info" now={uploadProfileStatus} />
                        </Alert>
                    }


                    {/* <Button variant='primary' type='submit' className='btnLogin saveChgsBtn' onClick={window.goToNext}>
                        Next
                    </Button> */}
                    </div>
            </div>
        </div>
    );
}


export default EditProfile
