import React, { createContext, useContext, useEffect, useState } from 'react';

// Create the context
const LocationContext = createContext();

// Create a custom hook for easier usage
export const useLocation = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  const [currency, setCurrency] = useState(null); // State for currency info
  
  // Function to fetch user IP information
  const getUserIPInfo = () => {
    const defaultCity = "Nairobi";
    const defaultRegion = "Nairobi Default";
    const defaultCountry = "KE";
    const defaultLoc = "-1.2833,36.8167"; // Default latitude and longitude for Nairobi

    return new Promise((resolve) => {
      fetch('https://ipinfo.io/json?token=0ea514b147e152')
        .then(response => {
          if (!response.ok) {
            throw new Error("Failed to fetch IP information");
          }
          return response.json();
        })
        .then(data => {
          const city = data.city || defaultCity;
          const region = data.region || defaultRegion;
          const country = data.country || defaultCountry;
          const loc = data.loc || defaultLoc;
          const [userLat, userLng] = loc.split(',');

          resolve({ 
            city, 
            region, 
            country,
            userLat,   // Add latitude
            userLng    // Add longitude 
          });
        })
        .catch(() => {
          // If there's an error, resolve with default values
          resolve({ 
            city: defaultCity, 
            region: defaultRegion, 
            country: defaultCountry,
            userLat: defaultLoc.split(',')[0], // Default latitude
            userLng: defaultLoc.split(',')[1]  // Default longitude
          });
        });
    });
  };

  // Function to fetch currency and full country name by country code
  const getCurrencyByCountryCode = (countryCode) => {
    return fetch(`https://restcountries.com/v3.1/alpha/${countryCode}`)
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to fetch country data");
        }
        return response.json();
      })
      .then(data => {
        const currencyInfo = data[0].currencies; // Get currency info
        const currency = currencyInfo ? Object.values(currencyInfo)[0] : null; // Get the first currency
        
        if (currency) {
          // Return currency details along with full country name
          return {
            name: currency.name,        // Currency name
            symbol: currency.symbol,    // Currency symbol
            code: Object.keys(currencyInfo)[0], // Currency code (e.g., "KES")
            countryName: data[0].name.common // Full country name
          };
        } else {
          return {
            name: "Currency not found",
            symbol: null,
            code: null,
            countryName: "Country not found"
          };
        }
      })
      .catch(error => {
        console.error("Error fetching currency:", error);
        return {
          name: "Currency not found",
          symbol: null,
          code: null,
          countryName: "Country not found"
        }; // Fallback message
      });
  };

  // Fetch user location and currency info when the component mounts
  useEffect(() => {
    getUserIPInfo().then(location => {
      setLocation(location);
      // Fetch currency based on the country code
      getCurrencyByCountryCode(location.country).then(currencyData => setCurrency(currencyData));
    });
  }, []);
  
  return (
    <LocationContext.Provider value={{ location, currency }}>
      {children}
    </LocationContext.Provider>
  );
};
 